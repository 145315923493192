import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { BsXSquareFill, BsPlusSquareFill } from "react-icons/bs";
import { ENDPOINTS, httpService, PagingOptions } from "../../api";
import { useEffect, useState } from "react";
import { Exam, Student, Subject } from "@models";

type Props = {
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};
const AddStudentExam = ({ reload, setReload }: Props) => {
  const [exams, setExams] = useState<Exam[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [subjects, setSubjects] = useState<Subject[]>([]);

  const validationSchema = Yup.object()
    .shape({
      examId: Yup.number().required("exam id is required"),
      studentId: Yup.number().required("student id is required"),
      results: Yup.array(
        Yup.object().shape({
          subjectId: Yup.number().required("subject id is required"),
          marks: Yup.number().required("Marks is required"),
        })
      ),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "results", // unique name for the Field Array
  });

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.CreateMultipleStudentExams).post(values);
    setReload(!reload);
  };

  useEffect(() => {
    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Exams, options).getAll();

      setExams(res.data.items ?? []);
    })();

    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Students, options).getAll();

      setStudents(res.data.items ?? []);
    })();

    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Subjects, options).getAll();

      setSubjects(res.data.items ?? []);
    })();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select {...register("examId")} id="examId" className="form-control">
            <option>Select exam</option>
            {exams.map((e) => (
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select
            {...register("studentId")}
            id="studentId"
            className="form-control"
          >
            <option>Select student</option>
            {students.map((s) => (
              <option key={s.id} value={s.id}>
                {`${s.firstName} ${s.lastName}`}
              </option>
            ))}
          </select>
        </div>
        {fields.map((item, idx) => {
          return (
            <div className="row w-100 mx-3 ">
              <div className="col-sm-4 col-md-4 col-lg-4 py-2">
                <select
                  {...register(`results.${idx}.subjectId`, {
                    required: "Subject id is required",
                  })}
                  id="subjectId"
                  className="form-control"
                >
                  <option value="Select subject">Select subject</option>
                  {subjects.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 py-2">
                <input
                  type="number"
                  className="form-control mr-2"
                  id="marks"
                  placeholder="marks"
                  {...register(`results.${idx}.marks`, {
                    required: "Marks is required",
                  })}
                  min={1}
                  max={100}
                />
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 py-3">
                <BsXSquareFill
                  style={{ cursor: "pointer", color: "#e7515a" }}
                  fontSize="30px"
                  onClick={() => remove(idx)}
                />
              </div>
            </div>
          );
        })}
      </div>

      <div style={{ alignSelf: "flex-start" }} className="mt-2">
        <span className="mr-2">Add Result</span>
        <BsPlusSquareFill
          style={{ cursor: "pointer" }}
          fontSize="30px"
          onClick={() => {
            append({ subjectId: "Select subject", marks: 0 });
          }}
        />
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default AddStudentExam;
