import { BaseModel } from "./baseModel";
import { Student } from "./Student";

export class Family extends BaseModel {
  code: string = "";
  name: string = "";
  parentName: string = "";
  email: string = " ";
  skypeAccount: string = "";
  whatsAppNumber: string = "";
  country: string = "";
  city: string = "";
  students: Student[] = [];

  constructor() {
    super();
  }

  getProperties() {
    return Object.keys(this);
  }
}

export class FamilyStats {
  name: string = "";
  noStudents: number = 0;
}
