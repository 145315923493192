import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaPlus } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  ENDPOINTS,
  httpService,
  PaginatedResult,
  PagingOptions,
} from "../../api";
import Loader from "../../components/common/loader";
import AddAssessment from "../../components/forms/addAssessment";
import { Assessment, TableHeader } from "@models";
import AssessmentsTable from "./assessmentsTable";

const headers: TableHeader[] = [
  { id: 1, name: "Student", align: "left" },
  { id: 2, name: "Subject", align: "left" },
  { id: 3, name: "Remarks", align: "left" },
  { id: 4, name: "Actions", align: "center" },
];

const Index = () => {
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(4);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const toggle = withReactContent(Swal);

  useEffect(() => {
    setLoading(true);

    (async function () {
      const options = new PagingOptions(page, perPage);
      const res = await httpService<Assessment>(
        ENDPOINTS.Assessments,
        options
      ).getAll();

      const {
        items,
        page: currentPage,
        size,
        totalPages,
      } = res.data as PaginatedResult<Assessment>;

      setAssessments(items);
      setPage(currentPage);
      setPerPage(size);
      setTotalPages(totalPages);
      setLoading(false);
    })();
  }, [page, perPage, reload]);

  const onCreateAssessment = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "50%",
      html: <AddAssessment reload={reload} setReload={setReload} />,
    });
  };

  return (
    <div className="widget-content widget-content-area mt-4 w-70">
      <div className="d-flex align-items-space-between align-content-center">
        <div style={{ flex: 1 }}>
          <div>
            <button
              className="btn btn-success mr-2"
              onClick={() => onCreateAssessment()}
            >
              <FaPlus /> Add Assessment
            </button>
          </div>
        </div>
      </div>

      {!loading ? (
        <AssessmentsTable
          page={page}
          perPage={perPage}
          headers={headers}
          assessments={assessments}
          setPage={setPage}
          setPerPage={setPerPage}
          totalPages={totalPages}
          reload={reload}
          setReload={setReload}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Index;
