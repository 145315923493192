import { ENDPOINTS, httpService } from "@api";
import { AssetCategory } from "@models";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useForm } from "react-hook-form";

type Props = {
  category: AssetCategory;
  reload: boolean;
  setReload: Dispatch<SetStateAction<boolean>>;
};

const EditAssetCategory = ({ category, reload, setReload }: Props) => {
  const [color, setColor] = useState("#aabbcc");

  useEffect(() => {
    setColor(category.color);
  }, []);
  const {
    handleSubmit,
    register,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm({
    defaultValues: {
      name: category.name,
      color: category.color,
    },
  });

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.AssetCategories).update(category.id, {
      ...values,
      color,
    });
    setReload(!reload);
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="name"
            placeholder="Name"
            {...register(`name`)}
          />
        </div>
        <HexColorPicker color={color} onChange={setColor} />
      </div>

      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default EditAssetCategory;
