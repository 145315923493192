import { Helmet } from "react-helmet";
import LoginForm from "../../components/forms/login";
import NewLogin from "./newLogin";

const Login = () => {
  return (
    <>
      <RenderHelmet />
      <NewLogin />
    </>
  );
};

function RenderHelmet() {
  return (
    <Helmet>
      <link
        href="assets/css/authentication/form-1.css"
        rel="stylesheet"
        type="text/css"
      />

      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/css/forms/theme-checkbox-radio.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/css/forms/switches.css"
      />

      <script src="assets/js/authentication/form-1.js"></script>
    </Helmet>
  );
}
export default Login;
