import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { BsXSquareFill, BsPlusSquareFill } from "react-icons/bs";
import { ENDPOINTS, httpService } from "../../api";
import { MonthlyAttendance, Period } from "core/models/MonthAttendance";
import DatePicker from "react-datepicker";
import { useEffect } from "react";

type Props = {
  monthlyAttendance: MonthlyAttendance;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddPeriod = ({ monthlyAttendance, reload, setReload }: Props) => {
  const validationSchema = Yup.object()
    .shape({
      periods: Yup.array(
        Yup.object().shape({
          // periodId: Yup.number().required("Period ID is required"),
          expectedDate: Yup.date().required("Expected date is required"),
          startDate: Yup.date().required("Start date is required"),
          duration: Yup.number().required("Duration is required"),
        })
      ),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      periods: monthlyAttendance.periods.map((period) => {
        return {
          periodId: period.id,
          expectedDate: new Date(period.expectedDate),
          startDate: new Date(period.startDate),
          duration: period.duration,
          isCallOnTime: period.isCallOnTime,
        };
      }),

      // periods: [
      //   {
      //     expectedDate: new Date(
      //       new Date().getFullYear(),
      //       monthlyAttendance.month - 1,
      //       1
      //     ),
      //     startDate: new Date(
      //       new Date().getFullYear(),
      //       monthlyAttendance.month - 1,
      //       1
      //     ),
      //     duration: 0,
      //   },
      // ],
    },
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "periods", // unique name for the Field Array
  });

  // Effect to handle async data fetch and reset form values
  // useEffect(() => {
  //   if (monthlyAttendance.periods.length) {
  //     reset({
  //       periods: monthlyAttendance.periods.map((period) => {
  //         return {
  //           periodId: period.id,
  //           expectedDate: new Date(period.expectedDate),
  //           startDate: new Date(period.startDate),
  //           duration: period.duration,
  //         };
  //       }),
  //     });
  //   }
  // }, [monthlyAttendance, reset]);

  const minDate = new Date(
    new Date().getFullYear(),
    monthlyAttendance.month - 1,
    1
  );
  const maxDate = new Date(
    new Date().getFullYear(),
    monthlyAttendance.month,
    0
  );

  const isCallOnTime = (isCallOnTime: boolean) => {
    if (isCallOnTime) {
      return "bg-success";
    }
    return "bg-danger";
  };

  const handleOnSubmit = async (values: any) => {
    if (values.periods.length === 0) return;

    const data = values.periods.map(
      (period: {
        periodId: number;
        day: number;
        expectedDate: Date;
        startDate: Date;
        duration: number;
      }) => {
        return {
          periodId: period.periodId,
          day: period.expectedDate.getDate(),
          expectedDate: period.expectedDate,
          startDate: period.startDate,
          duration: period.duration,
        };
      }
    );
    console.log("values", data);
    await httpService(ENDPOINTS.CreatePeriods).update(
      monthlyAttendance.id,
      data
    );
    setReload(!reload);
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 align-content-start"
    >
      <div className="row w-100">
        {fields.map((item: any, idx) => {
          console.log("item", item);
          return (
            <div className="row w-100 mx-3" key={idx}>
              <input
                type="hidden"
                id="periodId"
                {...register(`periods.${idx}.periodId`)}
              />
              <div className="col-sm-4 col-md-4 col-lg-4 py-2">
                <Controller
                  control={control}
                  name={`periods.${idx}.expectedDate`}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      showTimeSelect
                      maxDate={maxDate}
                      minDate={minDate}
                      timeIntervals={1}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className={`form-control ${
                        item && item.periodId && isCallOnTime(item.isCallOnTime)
                      }`}
                      popperClassName="react-datepicker-popper"
                    />
                  )}
                />
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 py-2">
                <Controller
                  control={control}
                  name={`periods.${idx}.startDate`}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      showTimeSelect
                      maxDate={maxDate}
                      minDate={minDate}
                      timeIntervals={1}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      className={`form-control ${
                        item && item.periodId && isCallOnTime(item.isCallOnTime)
                      }`}
                      popperClassName="react-datepicker-popper"
                    />
                  )}
                />
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 d-flex align-items-center justify-content-center">
                <input
                  type="number"
                  className={`form-control mr-2 ${
                    item && item.periodId && isCallOnTime(item.isCallOnTime)
                  }`}
                  id="duration"
                  placeholder="Duration"
                  {...register(`periods.${idx}.duration`, {
                    required: "Duration is required",
                  })}
                />

                <div>
                  <BsXSquareFill
                    style={{
                      cursor: "pointer",
                      color: "#e7515a",
                      display: `${
                        item && item?.periodId !== 0 ? "none" : "block"
                      }`,
                    }}
                    fontSize="30px"
                    onClick={() => remove(idx)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div style={{ alignSelf: "flex-start" }} className="mt-2">
        <span className="mr-2">Add Periods</span>
        <BsPlusSquareFill
          style={{ cursor: "pointer" }}
          fontSize="30px"
          onClick={() => {
            append({
              periodId: 0,
              expectedDate: new Date(
                new Date().getFullYear(),
                monthlyAttendance.month - 1,
                1
              ),
              startDate: new Date(
                new Date().getFullYear(),
                monthlyAttendance.month - 1,
                1
              ),
              duration: 0,
            });
          }}
        />
      </div>

      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default AddPeriod;
