import { useEffect, useState } from "react";
import {
  ENDPOINTS,
  Operation,
  PaginatedResult,
  PagingOptions,
  getUserInfo,
  httpService,
} from "@api";
import { Asset, AssetCategory, AssetStatus, Class, RoleType } from "@models";
import {
  Action,
  ComplexHeader,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { AddPeriod, CreateMonthlyAttendance } from "@components";
import { MonthlyAttendance } from "core/models/MonthAttendance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/reactDatePicker.css";
import { SearchFamily } from "@widgets";

const headers: ComplexHeader[] = [
  {
    key: "id",
    title: "Attendance ID",
  },
  {
    key: "family?.name",
    title: "Family",
    formatter: (name: string) => name.capitalize(),
  },
  {
    key: "class?.name",
    title: "Class",
    formatter: (name: string) => name.capitalize(),
  },
  {
    key: "month",
    title: "Month",
  },
  {
    key: "noStudents",
    title: "Students",
  },
];

const MonthlyAttendanceTable = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();

  const [reload, setReload] = useState(false);
  const [attendances, setAttendances] = useState<
    PaginatedResult<MonthlyAttendance>
  >(new PaginatedResult());

  const [classes, setClasses] = useState<PaginatedResult<Class>>(
    new PaginatedResult()
  );

  const [selectedClassId, setSelectedClassId] = useState<number | null>(null);

  const currentUser = getUserInfo();

  const [selectedFamily, setSelectedFamily] = useState<number | null>(null);

  const [loading, setLoading] = useState(false);

  const toggle = withReactContent(Swal);

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (selectedFamily) {
        queryStrings.set("familyId", selectedFamily.toString());
        setSelectedFamily(null);
      }

      if (selectedClassId) {
        queryStrings.set("classId", selectedClassId.toString());
        setSelectedClassId(null);
      }

      const res = await httpService(
        ENDPOINTS.MonthlyAttendance,
        queryStrings
      ).getAll();

      const data = res.data as PaginatedResult<MonthlyAttendance>;
      setAttendances(data ?? []);
      setLoading(false);
    })();
  }, [queryStrings, reload]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const options = new PagingOptions();
      options.size = 1000;

      const res = await httpService(ENDPOINTS.Classes, options).getAll();

      const data = res.data as PaginatedResult<Class>;
      setClasses(data ?? []);
    })();
  }, []);

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (monthlyAttendance: MonthlyAttendance) => {
        toggle.fire({
          showConfirmButton: false,
          showCloseButton: true,
          allowOutsideClick: false,
          width: "80%",
          // heightAuto: false,
          html: (
            <AddPeriod
              monthlyAttendance={monthlyAttendance}
              reload={reload}
              setReload={setReload}
            />
          ),
        });
      },
      title: "Add Periods",
      color: "primary",
    },
    {
      key: "",
      actionType: "badge",
      hide: () => currentUser?.roleName !== RoleType.ADMIN,
      click: async (a: Asset) => {
        Swal.fire({
          title: "Are you sure you want to delete this Asset?",
          // text: "By confirming deletion, this process is irrevocable, and the Billing will be permanently deleted.",
          icon: "warning",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, roll back!",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            await httpService(ENDPOINTS.MonthlyAttendance).delete(a.id);
            setReload((prev) => (prev === undefined ? true : !prev));
          },
        });
      },
      title: "Delete",
      color: "danger",
    },
  ];

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const filters: Filterable<MonthlyAttendance>[] = [
    {
      key: "familyId",
      title: "Family",
      format: "jsxElement",
      operation: Operation.like,
      element: (
        <div className="col-6">
          <SearchFamily setSelectedFamily={setSelectedFamily} />
        </div>
      ),
    },
    {
      key: "classId",
      title: "Class",
      operation: Operation.eq,
      format: "jsxElement",
      element: (
        <div className="mt-2 mb-3">
          <select
            className="custom-select ml-3"
            onChange={(e) => setSelectedClassId(+e.target.value)}
          >
            <option value={undefined}></option>
            {classes.items.map((c) => {
              return <option value={c.id}>{c.name}</option>;
            })}
          </select>
        </div>
      ),
    },
    {
      key: "year",
      title: "Year",
      format: "number",
      operation: Operation.eq,
    },
    {
      key: "month",
      title: "Month",
      format: "number",
      operation: Operation.eq,
    },
  ];

  const onCreateMonthAttendance = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "40%",
      html: <CreateMonthlyAttendance reload={reload} setReload={setReload} />,
    });
  };

  return (
    <div className="widget-content widget-content-area mt-4 w-100">
      <div className="d-flex justify-content-between mb-2">
        <div className="col-4">
          <div className="d-flex w-100">
            <button
              className="badge badge-success mr-4 p-2"
              onClick={() => onCreateMonthAttendance()}
            >
              <FaPlus />
              {"  "}
              Create Attendance
            </button>
          </div>
        </div>
        <div className="col-4">
          <TableVerticalConfigs
            filters={filters}
            // exportAsXSLS={onExport}
            // onPrint={{
            //   ref: "reportcontents",
            //   prePrint: onPrint,
            // }}
            // ignorePageSizing={true}
          />
        </div>
      </div>
      <div className="col-12" id="reportcontents">
        <Table
          actions={actions}
          class="table table-sm table-striped table-hover mb-0"
          data={attendances ?? []}
          headers={headers}
          isFetchingPage={loading}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="d-flex justify-content-center mt-3"
        />
      </div>
    </div>
  );
};

export default MonthlyAttendanceTable;
