import withReactContent from "sweetalert2-react-content";
import { FaEdit, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import { ENDPOINTS, httpService } from "../../api";
import Paginate from "../../components/common/paginate";
import Delete from "../../components/forms/delete";
import { Class, TableHeader } from "@models";
import EditClass from "../../components/forms/editClass";

type Props = {
  classes: Class[];
  page: number;
  perPage: number;
  totalPages: number;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  headers: TableHeader[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
};

const ClassesTable = ({
  classes,
  page,
  perPage,
  totalPages,
  headers,
  reload,
  setPage,
  setPerPage,
  setReload,
}: Props) => {
  const toggle = withReactContent(Swal);

  const handleDelete = async (id: number) => {
    await httpService(ENDPOINTS.Classes).delete(id);
    setReload(!reload);
  };

  const onDelete = (id: number) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <Delete id={id} onDelete={handleDelete} />,
    });
  };

  const onEditClass = (oldClass: Class) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "50%",
      html: (
        <EditClass oldClass={oldClass} reload={reload} setReload={setReload} />
      ),
    });
  };

  return (
    <div className="table-responsive mb-4 px-2 mt-4">
      <table className="table w-100">
        <thead>
          <tr>
            {headers.map((h) => (
              <th
                className={`${
                  h.align === "left" ? "text-left" : "text-center"
                }`}
                key={h.id}
              >
                {h.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {classes.length > 0 &&
            classes.map((c) => {
              return (
                <tr key={c.id}>
                  <td>{c.name}</td>
                  <td>{c.teacher?.firstName}</td>
                  <td className="text-center">
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => onEditClass(c)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger btn-sm ml-2"
                      onClick={() => onDelete(c.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="w-50">
        <Paginate
          canPreviousPage={page > 0 && true}
          canNextPage={totalPages !== page && true}
          currentPage={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          totalPage={totalPages}
        />
      </div>
    </div>
  );
};

export default ClassesTable;
