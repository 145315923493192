import { useEffect, useState } from "react";
import { ENDPOINTS, httpService, PagingOptions } from "../../api";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { Family } from "@models";

type Props = {
  onHandleChange: (value: any) => void;
};

const FilterByFamily = ({ onHandleChange }: Props) => {
  const [families, setFamilies] = useState<Family[]>([]);

  useEffect(() => {
    (async function () {
      const options = new PagingOptions();
      options.size = 10_000;
      const res = await httpService(ENDPOINTS.Families, options).getAll();

      setFamilies(res.data.items ?? []);
    })();
  }, []);

  const onChange = (e: any) => {
    onHandleChange(e.target.value);
  };

  return (
    <div className="form-group">
      <div className="form-group">
        <select
          id="familyId"
          className="form-control form-control-sm"
          onChange={onChange}
        >
          <option value="all">All Family</option>
          {families.map((f) => {
            return (
              <option key={f.id} value={f.id}>
                {toUpperCaseFirstLetter(f.name)}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default FilterByFamily;
