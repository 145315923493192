import { Student } from "@models";
import { exportDataCSV } from "../../helpers/fnHelpers";
import { useState } from "react";

type Props = {
  students: Student[];
};

function FilterDataColumns({ students }: Props) {
  const [selectedKeys, setSelectedKeys] = useState<string[]>(
    new Student().getProperties()
    //   .filter(
    //     (k) =>
    //       k !== "id" &&
    //       k !== "classId" &&
    //       k !== "createdAt" &&
    //       k !== "createdById"
    //   )
  );

  const getPropertyName = (name: string) => {
    if (name === "code") {
      return "studentId";
    } else {
      return name;
    }
  };

  const onChangeCheckbox = (id: string) => {
    if (selectedKeys.includes(id)) {
      setSelectedKeys((prev) => prev.filter((k) => k !== id));
    } else {
      setSelectedKeys((prev) => [...prev, id]);
    }
  };

  return (
    <div className="d-flex flex-column w-100">
      <div className="grid">
        {new Student()
          .getProperties()
          //   .filter(
          //     (k) =>
          //       k !== "id" &&
          //       k !== "classId" &&
          //       k !== "createdAt" &&
          //       k !== "createdById"
          //   )
          .map((k) => {
            return (
              <div className="d-flex w-50 justify-content-between mb-2">
                <span className="new-control-indicator mr-3">
                  {getPropertyName(k)}
                </span>
                <input
                  type="checkbox"
                  value={k}
                  onChange={() => onChangeCheckbox(k)}
                  className="new-control-input"
                  name={k}
                  defaultChecked
                  style={{
                    width: "20px",
                  }}
                />
              </div>
            );
          })}
      </div>
      <button
        className="align-self-end btn btn-sm btn-primary "
        onClick={() => exportDataCSV(selectedKeys, students)}
      >
        Generate
      </button>
    </div>
  );
}

export default FilterDataColumns;
