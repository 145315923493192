import { useEffect, useRef, useState } from "react";
import { FaPlus, FaPrint } from "react-icons/fa";
import DatePicker from "react-datepicker";
import withReactContent from "sweetalert2-react-content";
import { useReactToPrint } from "react-to-print";
import { ENDPOINTS, httpService } from "../../api";
import Swal from "sweetalert2";
import { StudentMilestone } from "@models";
import {
  AddClassPeriod,
  AddStudentMilestone,
  ClassFamilyStudentFilter,
  ClassFilter,
  FamilyFilter,
} from "@components";
import { MyBarLoader } from "@shared";
import { StudentMilestoneTable } from "./studentMilestoneTable";

const StudentMilestones = () => {
  const componentRef = useRef(null);

  const [familyId, setFamilyId] = useState<number>();
  const [studentId, setStudentId] = useState<number>();
  const [classId, setClassId] = useState<number>();

  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [studentMilestones, setStudentMilestones] = useState<
    StudentMilestone[]
  >([]);
  const toggle = withReactContent(Swal);

  const onClassChange = (id: number) => {
    setFamilyId(undefined);
    setStudentId(undefined);
    setClassId(id);
  };

  const onFamilyChange = (id: number) => {
    setStudentId(undefined);
    setClassId(undefined);
    setFamilyId(id);
  };

  const onStudentChange = (id: number) => {
    setFamilyId(undefined);
    setClassId(undefined);
    setStudentId(id);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSubmit = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "60%",
      html: <AddStudentMilestone reload={reload} setReload={setReload} />,
    });
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const options = new URLSearchParams();

      classId && options.append("classId", classId.toString());
      familyId && options.append("familyId", familyId.toString());
      studentId && options.append("studentId", studentId.toString());

      const res = await httpService(
        ENDPOINTS.StudentsMilestone,
        options
      ).getAll();
      setStudentMilestones(res.data ?? []);
      setIsLoading(false);
    })();

    return () => {};
  }, [reload, classId, familyId, studentId]);

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex align-items-start">
        {/* <span className="mt-1">
          <FilterByClass onHandleChange={onClassChange} />
        </span> */}
        {/* <span className="mt-1 ml-2">
          <FilterByFamily onHandleChange={onFamilyChange} />
        </span> */}
        <span className="mt-1">
          <ClassFamilyStudentFilter
            onClassChange={onClassChange}
            onFamilyChange={onFamilyChange}
            onStudentChange={onStudentChange}
          />
        </span>

        {/* <span className="mx-2 mt-1">
          <button className="btn btn-success" onClick={onGenerate}>
            {isGenerating && (
              <span className="spinner-border spinner-grow-sm text-white mr-2" />
            )}
            Generate
          </button>
        </span> */}
        <span className="ml-2 mt-2">
          <button className="btn btn-primary" onClick={handleSubmit}>
            <FaPlus />
          </button>
        </span>
        <span className="ml-2 mt-2">
          <button
            className="btn btn-primary"
            onClick={handlePrint}
            disabled={studentMilestones.length ? false : true}
          >
            <FaPrint />
          </button>
        </span>
      </div>
      {isLoading ? (
        <MyBarLoader />
      ) : (
        <StudentMilestoneTable
          milestones={studentMilestones ?? []}
          setReload={setReload}
          reload={reload}
          ref={componentRef}
        />
      )}
    </div>
  );
};

export default StudentMilestones;
