import { ENDPOINTS, getUserInfo, httpService, SECRET_KEY } from "@api";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RoleType } from "@models";
import { UserInfoViewModel } from "@viewModels";
import jwtDecode from "jwt-decode";
import crypto from "crypto-js";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type LoginType = {
  username: string;
  password: string;
};

const NewLogin = () => {
  const navigate = useNavigate();
  const validationSchema = Yup.object()
    .shape({
      username: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
    })
    .required();

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    formState: { isSubmitting },
    handleSubmit,
    register,
  } = useForm<LoginType>();

  // const onSubmit = async (values: any) => {
  //   const res = await httpService(ENDPOINTS.Auth).post(values);

  //   localStorage.setItem("token", res.data.token);

  //   const user = getCurrentUser();

  //   user?.roleName === RoleType.ADMIN
  //     ? navigate("/dashboard")
  //     : navigate("/dashboard/attendance");

  //   window.location.reload();
  // };

  const onSubmit = async (values: LoginType) => {
    const res = await httpService(ENDPOINTS.Auth).post(values);

    if (res && res.status === 200) {
      const userInfo = decodeToken(res?.data?.token);

      // Encrypt
      var ciphertext = crypto.AES.encrypt(
        JSON.stringify(userInfo),
        SECRET_KEY
      ).toString();

      localStorage.clear();

      localStorage.setItem("sys_user", JSON.stringify(ciphertext));
      const user = getUserInfo();

      user?.roleName === RoleType.ADMIN
        ? navigate("/dashboard")
        : navigate("/dashboard/attendance");

      window.location.reload();
    }
  };

  const decodeToken = (token: string) => {
    var decode: any = jwtDecode(token);

    var userInfo: UserInfoViewModel = {
      userId: decode?.userId,
      userName: decode?.userName,
      fullName: decode?.fullName,
      roleName: decode?.roleName,
      rcdCode: decode?.rcdCode,
      classId: decode?.classId,
      email: decode?.email,
      // roleId: decode?.roleId,
      // roleName: decode?.roleName,
      // accessLevel: decode?.accessLevel,
      // branchId: decode?.branchId,

      token: token,
    };

    return userInfo;
  };

  return (
    <div className="form-container">
      <div className="form-form">
        <div className="form-form-wrap">
          <div className="form-container">
            <div className="form-content">
              <h1 className="mb-4">
                Log In to{" "}
                <a href="index.html">
                  <span className="brand-name text-success">AmiinSys</span>
                </a>
              </h1>

              <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
                <div className="form">
                  <div id="username-field" className="field-wrapper input">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-user text-success"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <input
                      id="username"
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      {...register("username")}
                    />
                  </div>

                  <div id="password-field" className="field-wrapper input mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-lock text-success"
                    >
                      <rect
                        x="3"
                        y="11"
                        width="18"
                        height="11"
                        rx="2"
                        ry="2"
                      ></rect>
                      <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                    </svg>
                    <input
                      id="password"
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      {...register("password")}
                    />
                  </div>
                  <div className="d-sm-flex justify-content-between">
                    <div className="field-wrapper toggle-pass">
                      <p className="d-inline-block">Show Password</p>
                      <label className="switch s-success">
                        <input
                          type="checkbox"
                          id="toggle-password"
                          className="d-none"
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="field-wrapper">
                      <button type="submit" className="btn btn-success">
                        {isSubmitting && (
                          <span
                            style={{ fontSize: "10px", textAlign: "center" }}
                            className="spinner-border text-white mr-4"
                          />
                        )}
                        Log In
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <p className="terms-conditions">
                {new Date().getFullYear()} All Rights Reserved. This site is a
                product of{" "}
                <a
                  className="text-success"
                  href="https://amiinquranonline.com"
                  target="_blank"
                >
                  Amiin Quran Online.
                </a>
                <span>
                  Need to know more about us? visit our website at{" "}
                  <a
                    className="text-success"
                    href="https://amiinquranonline.com"
                    target="_blank"
                  >
                    amiinquranonline.com
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="form-image">
        <div className="l-image"></div>
      </div>
    </div>
  );
};

export default NewLogin;
