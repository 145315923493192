import { forwardRef } from "react";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { Student } from "@models";

export const StudentsReportTable = forwardRef((props: any, ref: any) => {
  return (
    <div
      ref={ref}
      className="d-flex bg-white flex-column mt-4 align-items-center"
    >
      <div className="d-flex align-items-center align-content-space-center my-5">
        <div className="mr-4">
          <h6 className="font-weight-bold text-align-center">
            Amiin Quran Online
          </h6>
          <hr />
        </div>
        <div className="align-self-center">
          <img src="/assets/img/logo_green.png" alt="logo" />
        </div>
        <div className="ml-3">
          <h6 className="font-weight-bold text-align-center">Student Report</h6>
          <hr />
        </div>
      </div>
      <table className="table bg-white">
        <thead className="bg-white ">
          <tr>
            <th className="text-left " scope="col">
              Family ID
            </th>
            <th className="text-left " scope="col">
              Student ID
            </th>
            <th className="text-left" scope="col">
              Student Name
            </th>
            <th className="text-left" scope="col">
              Gender
            </th>
            <th className="text-left" scope="col">
              Country
            </th>
            <th className="text-left" scope="col">
              Class
            </th>
            <th className="text-left" scope="col">
              Family
            </th>
          </tr>
        </thead>
        <tbody>
          {props.students.map((s: Student) => {
            return (
              <tr key={s.id}>
                <td className="text-left font-weight-bold">
                  {toUpperCaseFirstLetter(s.family?.code ?? "")}
                </td>
                <td className="text-left font-weight-bold">
                  {toUpperCaseFirstLetter(s.code)}
                </td>
                <td className="text-left font-weight-bold">
                  {toUpperCaseFirstLetter(
                    `${s.firstName} ${s.secondName} ${s.lastName}`
                  )}
                </td>
                <td className="text-left font-weight-bold">
                  {toUpperCaseFirstLetter(s.gender)}
                </td>
                <td className="text-left font-weight-bold">
                  {toUpperCaseFirstLetter(s.family?.country ?? "")}
                </td>
                <td className="text-left font-weight-bold">
                  {toUpperCaseFirstLetter(s.class?.name ?? "")}
                </td>
                <td className="text-left font-weight-bold">
                  {toUpperCaseFirstLetter(s.family?.name ?? "")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});
