import React from "react";
import { FaRegNewspaper, FaUsers } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import { getCurrentUser, getUserInfo } from "../api";
import { RoleType } from "@models";

const NewSideBar = () => {
  const { pathname } = useLocation();

  const isActiveLink = (link: string) => {
    return pathname.split("/")[pathname.split("/").length - 1] === link;
  };

  const user = getUserInfo();

  return (
    <div className="sidebar-wrapper sidebar-theme">
      <nav id="sidebar">
        <div className="shadow-bottom"></div>
        <ul className="list-unstyled menu-categories" id="accordionExample">
          {user?.roleName === RoleType.ADMIN && (
            <>
              <li className="menu">
                <NavLink to="/dashboard" className="dropdown-toggle">
                  <div className="">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-home"
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg> */}
                    <span>Dashboard</span>
                  </div>
                </NavLink>
              </li>
              <li className="menu">
                <NavLink to="/dashboard/classes" className="dropdown-toggle">
                  <div className="">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-clipboard"
                    >
                      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                      <rect
                        x="8"
                        y="2"
                        width="8"
                        height="4"
                        rx="1"
                        ry="1"
                      ></rect>
                    </svg> */}
                    <span>Classes</span>
                  </div>
                </NavLink>
              </li>
              <li className="menu">
                <NavLink to="/dashboard/teachers" className="dropdown-toggle">
                  <div className="">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-clipboard"
                    >
                      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                      <rect
                        x="8"
                        y="2"
                        width="8"
                        height="4"
                        rx="1"
                        ry="1"
                      ></rect>
                    </svg> */}
                    <span>Teachers</span>
                  </div>
                </NavLink>
              </li>
              <li className="menu">
                <NavLink to="/dashboard/families" className="dropdown-toggle">
                  <div className="">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-clipboard"
                    >
                      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                      <rect
                        x="8"
                        y="2"
                        width="8"
                        height="4"
                        rx="1"
                        ry="1"
                      ></rect>
                    </svg> */}
                    <span>Families</span>
                  </div>
                </NavLink>
              </li>
              <li className="menu">
                <NavLink
                  to="/dashboard/assessments"
                  className="dropdown-toggle"
                >
                  <div className="">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-clipboard"
                    >
                      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                      <rect
                        x="8"
                        y="2"
                        width="8"
                        height="4"
                        rx="1"
                        ry="1"
                      ></rect>
                    </svg> */}
                    <span>Assessments</span>
                  </div>
                </NavLink>
              </li>

              <li className="menu">
                <NavLink to="/dashboard/subjects" className="dropdown-toggle">
                  <div className="">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-clipboard"
                    >
                      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                      <rect
                        x="8"
                        y="2"
                        width="8"
                        height="4"
                        rx="1"
                        ry="1"
                      ></rect>
                    </svg> */}
                    <span>Subjects</span>
                  </div>
                </NavLink>
              </li>

              <li className="menu">
                <a
                  href="#exams"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div className="">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-clipboard"
                    >
                      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                      <rect
                        x="8"
                        y="2"
                        width="8"
                        height="4"
                        rx="1"
                        ry="1"
                      ></rect>
                    </svg> */}
                    <span>Exams</span>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-chevron-right"
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                </a>
                <ul
                  className="collapse submenu list-unstyled"
                  id="exams"
                  data-parent="#accordionExample"
                >
                  <li>
                    <NavLink to="/dashboard/exams" className="dropdown-toggle">
                      Exams
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/studentExams"
                      className="dropdown-toggle"
                    >
                      Student Exams
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="menu">
                <a
                  href="#users"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div className="">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-users"
                    >
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg> */}
                    <span>Users</span>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-chevron-right"
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                </a>
                <ul
                  className="collapse submenu list-unstyled"
                  id="users"
                  data-parent="#accordionExample"
                >
                  <li>
                    <NavLink
                      to="/dashboard/profile"
                      className="dropdown-toggle"
                    >
                      Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/users" className="dropdown-toggle">
                      Users
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/roles" className="dropdown-toggle">
                      Roles
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="menu">
                <a
                  href="#reports"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div className="">
                    {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-clipboard"
                >
                  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                  <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                </svg> */}
                    <span>Reports</span>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-chevron-right"
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                </a>
                <ul
                  className="collapse submenu list-unstyled"
                  id="reports"
                  data-parent="#accordionExample"
                >
                  <li>
                    <NavLink
                      to="/dashboard/reports/students"
                      className="dropdown-toggle"
                    >
                      Students
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/dashboard/reports/attendance"
                      className="dropdown-toggle"
                    >
                      Create Attendance
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/dashboard/reports/attendanceReport"
                      className="dropdown-toggle"
                    >
                      Attendance Report
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/dashboard/reports/classPeriods"
                      className="dropdown-toggle"
                    >
                      Class Periods
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/reports/studentMilestone"
                      className="dropdown-toggle"
                    >
                      Student Milestone
                    </NavLink>
                  </li>

                  {/* <li>
                <NavLink
                  to="/dashboard/reports/families"
                  className="dropdown-toggle"
                >
                  Families
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/dashboard/reports/classes"
                  className="dropdown-toggle"
                >
                  Classes
                </NavLink>
              </li> */}
                </ul>
              </li>
              <li className="menu">
                <a
                  href="#assets"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div className="">
                    {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-clipboard"
                >
                  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                  <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                </svg> */}
                    <span>Assets</span>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-chevron-right"
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                </a>
                <ul
                  className="collapse submenu list-unstyled"
                  id="assets"
                  data-parent="#accordionExample"
                >
                  <li>
                    <NavLink to="/dashboard/assets" className="dropdown-toggle">
                      Assets
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="/dashboard/assets/categories"
                      className="dropdown-toggle"
                    >
                      Categories
                    </NavLink>
                  </li>
                </ul>
              </li>
            </>
          )}

          <li className="menu">
            <NavLink to="/dashboard/attendance" className="dropdown-toggle">
              <div className="">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-clipboard"
                >
                  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                  <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                </svg> */}
                <span>Attendance</span>
              </div>
            </NavLink>
          </li>
        </ul>
        <div className="shadow-bottom"></div>
      </nav>
    </div>
  );
};

export default NewSideBar;
