import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Assessment, Class, Student, Subject, Teacher } from "@models";
import { BsXSquareFill, BsPlusSquareFill } from "react-icons/bs";
import { useFieldArray, useForm } from "react-hook-form";
import { ENDPOINTS, httpService, PagingOptions } from "../../api";
import { useEffect, useState } from "react";

type Props = {
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};
const AddAssessment = ({ reload, setReload }: Props) => {
  const [students, setStudents] = useState<Student[]>([]);
  const [subjects, setSubjects] = useState<Subject[]>([]);

  const validationSchema = Yup.object()
    .shape({
      studentId: Yup.number().required("Student id is required"),
      assessmentResults: Yup.array(
        Yup.object().shape({
          subjectId: Yup.number().required("Subject id is required"),
          remarks: Yup.string().required("Remarks is required"),
        })
      ),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting },
  } = useForm<any>(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.Assessments).post(values);
    setReload(!reload);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "assessmentResults", // unique name for the Field Array
  });

  useEffect(() => {
    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Students, options).getAll();

      setStudents(res.data.items ?? []);
    })();

    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Subjects, options).getAll();

      setSubjects(res.data.items ?? []);
    })();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-12 col-md-12 col-lg-12 py-3">
          <select
            {...register("studentId")}
            id="studentId"
            className="form-control"
          >
            <option>Select student</option>
            {students.map((s) => (
              <option key={s.id} value={s.id}>
                {`${s.firstName} ${s.lastName}`}
              </option>
            ))}
          </select>
        </div>

        {fields.map((item, idx) => {
          return (
            <div className="row w-100 mx-3 ">
              <div className="col-sm-4 col-md-4 col-lg-4 py-3">
                <select
                  {...register(`assessmentResults.${idx}.subjectId`, {
                    required: "Subject id is required",
                  })}
                  id="subjectId"
                  className="form-control"
                >
                  <option>Select subject</option>
                  {subjects.map((s) => (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 py-3">
                <textarea
                  className="form-control mr-2"
                  id="remarks"
                  placeholder="remarks"
                  {...register(`assessmentResults.${idx}.remarks`, {
                    required: "Remarks is required",
                  })}
                />
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 py-3">
                <BsXSquareFill
                  style={{ cursor: "pointer", color: "#e7515a" }}
                  fontSize="30px"
                  onClick={() => remove(idx)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ alignSelf: "flex-start" }} className="mt-2">
        <span className="mr-2">Add Result</span>
        <BsPlusSquareFill
          style={{ cursor: "pointer" }}
          fontSize="30px"
          onClick={() => {
            append({ subjectId: "Select subject", remarks: "" });
          }}
        />
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default AddAssessment;
