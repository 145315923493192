import { Navigate } from "react-router";
import { getUserInfo } from "../api";
import { useLocation } from "react-router-dom";
import { RoleType } from "@models";

type Props = {
  children: any;
};

function formatPathname(path: string) {
  if (path.includes("/")) {
    // Remove the first character if it's '/'
    if (path.startsWith("/")) {
      path = path.slice(1);
    }
    // Remove the last character if it's '/'
    if (path.endsWith("/")) {
      path = path.slice(0, -1);
    }

    return path;
  }

  return path;
}
const RequiredAuth = ({ children }: Props) => {
  const user = getUserInfo();
  const { pathname } = useLocation();
  const length = pathname.split("/").length;

  const protectedRoutes = [
    "dashboard",
    "dashboard/classes",
    "dashboard/teachers",
    "dashboard/users",
    "dashboard/families",
    "dashboard/exams",
    "dashboard/studentExams",
    "dashboard/assessments",
    "dashboard/assets",
    "dashboard/reports/",
    "dashboard/assets/categories",
    "dashboard/reports/students",
  ];

  return !user.userId ? (
    <Navigate to="/login" replace />
  ) : user.roleName !== RoleType.ADMIN &&
    protectedRoutes.includes(formatPathname(pathname)) ? (
    <Navigate to="/login" replace />
  ) : (
    children
  );
};

export default RequiredAuth;
