import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { TableHeader, User } from "@models";
import Delete from "../../components/forms/delete";
import { ENDPOINTS, httpService } from "../../api";
import { ResetPassword } from "../../components/forms/resetPassword";
import { EditUser } from "../../components/forms/editUser";

type Props = {
  headers: TableHeader[];
  users: User[];
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const UsersTable = ({ headers, users, reload, setReload }: Props) => {
  const toggle = withReactContent(Swal);

  const onEdit = (user: User) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "50%",
      html: <EditUser user={user} reload={reload} setReload={setReload} />,
    });
  };

  const handleDelete = async (id: number) => {
    await httpService(ENDPOINTS.Users).delete(id);
    setReload(!reload);
  };

  const onDelete = (id: number) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <Delete id={id} onDelete={handleDelete} />,
    });
  };

  const onResetPassword = (user: User) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <ResetPassword user={user} reload={reload} setReload={setReload} />,
    });
  };

  return (
    <table className="table ">
      <thead>
        <tr>
          {headers.map((h) => (
            <th
              className={`${h.name === "Actions" ? "text-center" : ""}`}
              key={h.id}
            >
              {h.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          return (
            <tr key={user.id}>
              <td>
                {`${toUpperCaseFirstLetter(user.firstName)}
                    ${toUpperCaseFirstLetter(user.lastName)}`}
              </td>

              <td>{toUpperCaseFirstLetter(user.userName)}</td>

              <td>{toUpperCaseFirstLetter(user?.role?.name ?? "")}</td>
              <td>{moment(user.createdAt).format("ll")}</td>
              <td className="text-center">
                <button
                  className="btn btn-outline-success mr-2"
                  onClick={() => onEdit(user)}
                >
                  Edit
                </button>

                <button
                  className="btn btn-outline-warning mr-2"
                  onClick={() => onResetPassword(user)}
                >
                  Reset
                </button>

                <button
                  className="btn btn-outline-danger r mr-2"
                  onClick={() => onDelete(user.id)}
                  // disabled={currentUser?.roleName !== "Super.Admin"}
                >
                  Delete
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default UsersTable;
