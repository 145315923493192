import { useState } from "react";

type Props = {
  id: number;
  onDelete: (id: number) => void;
};
const Delete = ({ id, onDelete }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleDelete = () => {
    setIsSubmitting(true);
    setTimeout(() => {
      onDelete(id);
      setIsSubmitting(false);
    }, 2000);
  };
  return (
    <div className="d-flex flex-column mt-4">
      <div className="form-group d-flex flex-column align-items-start align-content-center">
        <h3>Are you sure to delete?</h3>
      </div>
      <button
        type="submit"
        className="btn btn-danger mt-3 align-self-end"
        onClick={handleDelete}
        disabled={isSubmitting}
      >
        Delete
      </button>
    </div>
  );
};

export default Delete;
