import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Pie } from "react-chartjs-2";
import { ClassStats } from "@models";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const options = {
  plugins: {
    title: {
      display: false,
      text: "Class Chart",
    },
    legend: {
      display: false,
    },
  },
};

type Props = {
  classStats: ClassStats[];
};

export default function ClassChart({ classStats }: Props) {
  const data = {
    labels: classStats.map((c) => c.name),
    datasets: [
      {
        label: "# of Students",
        backgroundColor: classStats.map(
          () => `#${((Math.random() * 0xffffff) << 0).toString(16)}`
        ),
        data: classStats.map((c) => c.noStudents),
      },
    ],
  };
  return (
    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 layout-spacing">
      <div className="widget-content widget-content-area">
        <div className="widget-heading">
          <h5 className="">Class Chart</h5>
        </div>
        <div className="row layout-top-spacing m-0 p-0">
          <Pie data={data} options={options} />
        </div>
      </div>
    </div>
  );
}
