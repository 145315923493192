import { useEffect, useState } from "react";
import { ENDPOINTS, PagingOptions, httpService } from "../../api";
import { Class, Family, Student } from "@models";
import { exportToCSV } from "../../helpers/fnHelpers";

const ClassReport = () => {
  const [classes, setClasses] = useState<Class[]>([]);
  const [classId, setClassId] = useState<number>();
  const [students, setStudents] = useState<Student[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>(
    new Student()
      .getProperties()
      .filter((k) => k !== "familyId" && k !== "classId")
  );

  useEffect(() => {
    (async () => {
      const options = new PagingOptions();
      options.size = 1000;

      const res = await httpService(ENDPOINTS.Classes, options).getAll();

      setClasses(res.data.items ?? []);
    })();
  }, []);

  useEffect(() => {
    if (classId) {
      const options = new PagingOptions();

      if (classId !== -1) {
        options.filter<Student>((s) => s.eq("classId", classId));
      }

      (async () => {
        const res = await httpService(ENDPOINTS.StudentList, options).getAll();
        setStudents(res.data ?? []);
      })();
    }
  }, [classId]);

  const onChangeCheckbox = (id: string) => {
    if (selectedKeys.includes(id)) {
      setSelectedKeys((prev) => prev.filter((k) => k !== id));
    } else {
      setSelectedKeys((prev) => [...prev, id]);
    }
  };

  return (
    <div className="d-flex flex-column w-100 p-3 mt-4 align-items-start">
      <div className="w-50 mb-2">
        <select
          className="form-control"
          onChange={(e) => {
            setClassId(+e.target.value);
          }}
        >
          <option value={undefined}>Select Class</option>
          <option value={-1}>All Classes</option>

          {classes?.map((c) => (
            <option key={c.id} value={c.id}>
              {`${c.name ?? ""} (${c.teacher?.firstName ?? ""})`}
            </option>
          ))}
        </select>
      </div>

      <div className="d-flex flex-column w-100 align-items-start">
        {new Student()
          .getProperties()
          .filter((k) => k !== "familyId" && k !== "classId")
          .map((k) => {
            return (
              <div className="d-flex w-15 justify-content-between mb-2">
                <span className="new-control-indicator mr-3">{k}</span>
                <input
                  type="checkbox"
                  value={k}
                  onChange={() => onChangeCheckbox(k)}
                  className="new-control-input"
                  name={k}
                  defaultChecked
                  style={{
                    width: "20px",
                    backgroundColor: "red",
                  }}
                />
              </div>
            );
          })}
      </div>

      <button
        className="align-self-end btn btn-sm btn-primary "
        onClick={() => exportToCSV(selectedKeys, students)}
        disabled={Boolean(!classId)}
      >
        Generate
      </button>
    </div>
  );
};

export default ClassReport;
