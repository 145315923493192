import { BaseModel } from "./baseModel";
import { Class } from "./Class";
import { Family } from "./Family";

export class Student extends BaseModel {
  code: string = "";
  firstName: string = "";
  secondName: string = "";
  lastName: string = "";
  gender: string = "";
  classId: number = 0;
  familyId: number = 0;
  family?: Family;
  class?: Class;

  constructor() {
    super();
  }

  getProperties() {
    return Object.keys(this);
  }
}
