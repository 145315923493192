import { useEffect, useState } from "react";
import { ENDPOINTS, PaginatedResult, PagingOptions, httpService } from "../api";

function useFetch<T>(
  endpoint: ENDPOINTS,
  options: PagingOptions | URLSearchParams,
  reload: boolean
) {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);

  async function fetchData() {
    setLoading(true);

    const res = await httpService(endpoint, options).getAll();

    const { items } = res.data as PaginatedResult<T>;

    setData(items ?? []);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [reload]);

  return { data, loading };
}

export default useFetch;
