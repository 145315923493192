import { useEffect, useRef, useState } from "react";
import { FaPlus, FaPrint } from "react-icons/fa";
import DatePicker from "react-datepicker";
import withReactContent from "sweetalert2-react-content";
import { useReactToPrint } from "react-to-print";
import {
  ENDPOINTS,
  PaginatedResult,
  PagingOptions,
  httpService,
} from "../../api";
import FilterByFamily from "../../components/dropdowns/filterByFamily";
import Swal from "sweetalert2";
import FilterByClass from "../../components/dropdowns/filterByClass";
import { ClassAttendanceReportTable } from "./classAttendanceReportTable";
import { ClassMonthlyAttendance, ClassPeriod } from "@models";
import { ClassPeriodsTable } from "./classPeriodsTable";
import { AddClassPeriod, ClassFilter } from "@components";
import moment from "moment";
import { MyBarLoader } from "@shared";

const ClassPeriods = () => {
  const componentRef = useRef(null);

  const [familyId, setFamilyId] = useState<number>();
  const [classId, setClassId] = useState<number>();

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [classPeriods, setClassPeriods] = useState<ClassPeriod[]>([]);
  const toggle = withReactContent(Swal);

  const onFamilyChange = (id: number) => {
    setClassId(undefined);
    setSelectedDate(null);
    setFamilyId(id);
  };

  const onClassChange = (id: number) => {
    setFamilyId(undefined);
    setSelectedDate(null);
    setClassId(id);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSubmit = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "40%",
      html: <AddClassPeriod reload={reload} setReload={setReload} />,
    });
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const options = new URLSearchParams();

      classId && options.append("classId", classId.toString());
      familyId && options.append("familyId", familyId.toString());
      selectedDate &&
        options.append("time", moment(selectedDate).format("HH").toString());

      const res = await httpService(ENDPOINTS.ClassPeriods, options).getAll();
      setClassPeriods(res.data ?? []);
      setIsLoading(false);
    })();

    return () => {};
  }, [reload, classId, familyId, selectedDate]);

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex align-items-start">
        <span className="mt-1 mr-2">
          <DatePicker
            className="form-control form-control-sm"
            onChange={(e) => {
              setClassId(undefined);
              setFamilyId(undefined);
              setSelectedDate(e);
            }}
            selected={selectedDate}
            timeInputLabel="Time:"
            dateFormat="h:mm aa"
            showTimeInput
            showTimeSelectOnly
            placeholderText="Select a time"
          />
        </span>
        {/* <span className="mt-1">
          <FilterByClass onHandleChange={onClassChange} />
        </span> */}
        {/* <span className="mt-1 ml-2">
          <FilterByFamily onHandleChange={onFamilyChange} />
        </span> */}

        <span className="mt-1">
          <ClassFilter
            onClassChange={onClassChange}
            onFamilyChange={onFamilyChange}
          />
        </span>
        {/* <span className="mx-2 mt-1">
          <button className="btn btn-success" onClick={onGenerate}>
            {isGenerating && (
              <span className="spinner-border spinner-grow-sm text-white mr-2" />
            )}
            Generate
          </button>
        </span> */}
        <span className="ml-2 mt-2">
          <button className="btn btn-primary" onClick={handleSubmit}>
            <FaPlus />
          </button>
        </span>
        <span className="ml-2 mt-2">
          <button
            className="btn btn-primary"
            onClick={handlePrint}
            disabled={classPeriods.length ? false : true}
          >
            <FaPrint />
          </button>
        </span>
      </div>
      {isLoading ? (
        <MyBarLoader />
      ) : (
        <ClassPeriodsTable
          classPeriods={classPeriods ?? []}
          setReload={setReload}
          reload={reload}
          ref={componentRef}
          selectedTime={
            selectedDate ? moment(selectedDate).format("HH") : undefined
          }
        />
      )}
    </div>
  );
};

export default ClassPeriods;
