import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ENDPOINTS, httpService } from "../../api";
import { useState } from "react";
import { HexColorPicker } from "react-colorful";

type Props = {
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateAssetCategory = ({ reload, setReload }: Props) => {
  const [color, setColor] = useState("#aabbcc");

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("Name is required"),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.AssetCategories).post({ ...values, color });
    setReload(!reload);
  };

  console.log("color", color);

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="name"
            placeholder="Name"
            {...register(`name`)}
          />
        </div>
        <HexColorPicker color={color} onChange={setColor} />
      </div>

      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default CreateAssetCategory;
