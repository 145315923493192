import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Class, Teacher } from "@models";
import { useForm } from "react-hook-form";
import { ENDPOINTS, httpService, PagingOptions } from "../../api";
import { useEffect, useState } from "react";

type Props = {
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};
const AddClass = ({ reload, setReload }: Props) => {
  const [teachers, setTeachers] = useState<Teacher[]>([]);

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("Name is required"),
      teacherId: Yup.number().required("Teacher is required"),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<Class>(formOptions);

  const handleOnSubmit = async (values: Class) => {
    await httpService(ENDPOINTS.Classes).post(values);
    setReload(!reload);
  };

  useEffect(() => {
    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Teachers, options).getAll();

      setTeachers(res.data.items ?? []);
    })();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="name"
            placeholder="Name"
            {...register(`name`)}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select
            {...register("teacherId")}
            id="teacherId"
            className="form-control"
          >
            <option value="Choose">Select teacher</option>
            {teachers.map((t) => (
              <option key={t.id} value={t.id}>
                {`${t.firstName} ${t.lastName}`}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default AddClass;
