import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Attendance, Family } from "@models";
import { useForm } from "react-hook-form";
import { ENDPOINTS, getUserInfo, httpService } from "../../api";
import { useEffect, useState } from "react";
import { getDaysFromMonth } from "../../helpers/fnHelpers";
import moment from "moment";

type Props = {
  year: number;
  month: number;
  attendances: Attendance[];
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};
const AddAttendance = ({
  reload,
  setReload,
  year,
  month,
  attendances,
}: Props) => {
  const [families, setFamilies] = useState<Family[]>([]);
  const user = getUserInfo();

  const validationSchema = Yup.object()
    .shape({
      familyId: Yup.string().required("Family id is required"),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isValid },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    if (month && user?.classId) {
      await httpService(ENDPOINTS.Attendances).post({
        familyId: +values.familyId,
        days: getDaysFromMonth(year, month),
        month,
        year,
        classId: +user?.classId,
      });

      setReload(!reload);
    }
  };

  useEffect(() => {
    (async () => {
      if (user?.classId) {
        const res = await httpService(ENDPOINTS.ClassFamilies).getById(
          user?.classId
        );
        setFamilies(res.data ?? []);
      }
    })();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-50">
        <div className="mx-2 w-100 px-2 mt-3">
          <select
            {...register("familyId")}
            id="familyId"
            className="form-control"
          >
            <option value="">Select family</option>
            {families.map((f) => (
              <option
                key={f.id}
                value={f.id}
                hidden={attendances.map((a) => a.familyId).includes(f.id)}
              >
                {`${f.name} (${f.code})`}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
          disabled={!isValid}
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Create
        </button>
      </div>
    </form>
  );
};

export default AddAttendance;
