import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  ENDPOINTS,
  httpService,
  PaginatedResult,
  PagingOptions,
} from "../../api";
import Loader from "../../components/common/loader";
import { TableHeader, Family } from "@models";
import FamiliesTable from "./familiesTable";
import AddFamily from "../../components/forms/addFamily";

const headers: TableHeader[] = [
  { id: 1, name: "Student Id", align: "left" },
  { id: 2, name: "Full Name", align: "left" },
  { id: 3, name: "Parent", align: "left" },
  { id: 4, name: "Email", align: "left" },
  { id: 5, name: "WhatsApp", align: "left" },
  { id: 6, name: "Skype", align: "left" },
  { id: 7, name: "Country", align: "left" },
  { id: 8, name: "City", align: "left" },
  { id: 9, name: "Class", align: "left" },
  { id: 10, name: "Gender", align: "left" },
  { id: 11, name: "Actions", align: "center" },
];

const Index = () => {
  const [families, setFamilies] = useState<Family[]>([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const toggle = withReactContent(Swal);

  const fetchFamilies = async (searchTerm: string) => {
    setLoading(true);
    const options = new PagingOptions(page, perPage);
    if (searchTerm) {
      options.page = 0;
      /\d/.test(searchTerm)
        ? options.filter<Family>((f) => f.like("code", searchTerm))
        : options.filter<Family>((f) => f.like("name", searchTerm));
    }
    const res = await httpService<Family>(ENDPOINTS.Families, options).getAll();
    const {
      items,
      page: currentPage,
      size,
      totalPages,
    } = res.data as PaginatedResult<Family>;
    setFamilies(items);
    setPage(currentPage);
    setPerPage(size);
    setTotalPages(totalPages);
    setLoading(false);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      fetchFamilies(searchTerm);
    }, 800);

    return () => clearTimeout(timeOutId);
  }, [searchTerm, reload, page, perPage]);

  const onCreateFamily = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "80%",
      html: <AddFamily reload={reload} setReload={setReload} />,
    });
  };

  // const onPrint = () => {
  //   toggle.fire({
  //     showConfirmButton: false,
  //     showCloseButton: true,
  //     allowOutsideClick: false,
  //     width: "70%",
  //     html: <StudentsReport />,
  //   });
  // };

  return (
    <div className="widget-content widget-content-area br-6 mb-4 mt-4">
      <div className="d-flex align-items-between align-content-center">
        <div className="search-input-group-style input-group w-25 mr-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search family..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <button
          className="btn btn-success mr-2"
          onClick={() => onCreateFamily()}
        >
          <FaPlus /> <span className="ml-2">Add Family</span>
        </button>
        {/* <button className="btn btn-success mr-2" onClick={() => onPrint()}>
          <FaRProject /> <span className="ml-2">Family Report</span>
        </button> */}
      </div>
      <div className="table-responsive mb-4 px-2 mt-4">
        {families.length > 0 ? (
          <FamiliesTable
            page={page}
            perPage={perPage}
            open={open}
            setOpen={setOpen}
            headers={headers}
            families={families}
            setPage={setPage}
            setPerPage={setPerPage}
            totalPages={totalPages}
            reload={reload}
            setReload={setReload}
          />
        ) : loading ? (
          <Loader />
        ) : (
          <span>No Data</span>
        )}
      </div>
    </div>
  );
};

export default Index;
