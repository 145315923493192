import * as Yup from "yup";
import { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { ENDPOINTS, httpService } from "../../api";
import Delete from "../../components/forms/delete";
import NestedArray from "./nestedArray";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AddRemark from "../../components/forms/addRemark";
import { Attendance } from "@models";

interface Props {
  selectedDate: Date | null;
  attendances: Attendance[];
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const AttendanceTable = ({
  reload,
  setReload,
  attendances,
  selectedDate,
}: Props) => {
  const [isEditing, setIsEditing] = useState("");
  const [currentAttId, setCurrentAttId] = useState(0);
  const [actionType, setActionType] = useState<"EDIT" | "DELETE">();
  const toggle = withReactContent(Swal);

  const validationSchema = Yup.object()
    .shape({
      // familyId: Yup.number().required("Family id is required"),
      days: Yup.array(
        Yup.object().shape({
          day: Yup.number().required("Day is required"),
          minutes: Yup.number().required("Minutes is required"),
        })
      ),
    })
    .required();

  const formOptions = {
    defaultValues: {
      attendances: attendances.map((a) => ({
        id: a.id,
        days: a.days.map((d) => ({
          day: d.day,
          minutes: d.minutes === 0 ? null : d.minutes,
        })),
      })),
    },
    resolver: yupResolver(validationSchema),
  };

  const { control, register, handleSubmit, reset } = useForm(formOptions);

  const { fields } = useFieldArray({
    control,
    name: "attendances", // unique name for the Field Array
  });

  useEffect(() => {
    reset({
      attendances: attendances.map((a) => ({
        id: a.id,
        days: a.days.map((d) => ({
          day: d.day,
          minutes: d.minutes === 0 ? null : d.minutes,
        })),
      })),
    });
  }, []);

  const handleDelete = async (id: number) => {
    await httpService(ENDPOINTS.Attendances).delete(id);
    setReload(!reload);
  };

  const onDelete = (id: number) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <Delete id={id} onDelete={handleDelete} />,
    });
  };

  const onSubmit = async (values: any) => {
    if (actionType && actionType === "EDIT") {
      const attProps = attendances.find((a) => a.id === currentAttId);

      const attFromForm = values.attendances.find(
        (a: Attendance) => a.id === currentAttId
      );

      if (attProps && attFromForm) {
        toggle.fire({
          showConfirmButton: false,
          showCloseButton: true,
          allowOutsideClick: false,
          width: "50%",
          html: (
            <AddRemark
              reload={reload}
              setReload={setReload}
              attendance={
                {
                  id: attFromForm.id,
                  days: attFromForm.days.map(
                    (d: { day: number; minutes: number }) => ({
                      day: d.day,
                      minutes: !d.minutes ? 0 : d.minutes,
                    })
                  ),
                  remarks: attProps?.remarks ?? "",
                } as Attendance
              }
            />
          ),
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="table-responsive mt-4 mb-4">
        <div
          id="example_wrapper"
          className="dataTables_wrapper container-fluid dt-bootstrap4"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                id="example"
                className="table table-hover dataTable"
                role="grid"
                aria-describedby="example_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting"
                      aria-controls="example"
                      aria-label="Name: activate to sort column ascending"
                    >
                      F.Name
                    </th>
                    {attendances.length > 0 &&
                      attendances[0].days.map((d) => {
                        return (
                          <th
                            className="sorting"
                            aria-controls="example"
                            aria-label="Name: activate to sort column ascending"
                            key={d.day}
                          >
                            {d.day <= 9 ? `0${d.day}` : d.day}
                          </th>
                        );
                      })}

                    <th
                      className="sorting"
                      aria-controls="example"
                      aria-label="Name: activate to sort column ascending"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                {attendances.length > 0 ? (
                  <tbody>
                    {fields.map((item, idx) => {
                      return (
                        <tr role="row" key={item.id}>
                          <td>
                            {`${attendances[idx]?.family?.name ?? ""}`}
                            <input
                              type="number"
                              className="form-control"
                              hidden
                              {...register(`attendances.${idx}.id`)}
                              value={`${attendances[idx]?.id}` ?? ""}
                              name={`attendances.${idx}.id`}
                            />
                          </td>
                          <NestedArray
                            nestIndex={idx}
                            {...{
                              control,
                              register,
                              attendances,
                            }}
                          />
                          <td>
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary p-1 mt-1 mr-1"
                              onClick={() => {
                                setActionType("EDIT");
                                setCurrentAttId(attendances[idx]?.id);
                              }}
                            >
                              <FaEdit />
                            </button>
                            <button
                              className="btn btn-sm btn-danger p-1 mt-1"
                              onClick={() => {
                                setActionType("DELETE");
                                onDelete(attendances[idx].id);
                              }}
                            >
                              <FaTrash />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <>No data!</>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>{" "}
    </form>
  );
};

export default AttendanceTable;
