import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Teacher } from "@models";
import { useForm } from "react-hook-form";
import { ENDPOINTS, httpService } from "../../api";

type Props = {
  teacher: Teacher;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditTeacher = ({ teacher, reload, setReload }: Props) => {
  const validationSchema = Yup.object()
    .shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      phone: Yup.string().required("Phone is required"),
      gender: Yup.string().required("Gender is required"),
    })
    .required();

  const formOptions = {
    defaultValues: {
      firstName: teacher.firstName,
      lastName: teacher.lastName,
      phone: teacher.phone,
      gender: teacher.gender,
    },
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<Teacher>(formOptions);

  const handleOnSubmit = async (values: Teacher) => {
    await httpService(ENDPOINTS.Teachers).update(teacher.id, values);
    setReload(!reload);
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="firstName"
            placeholder="First name"
            {...register(`firstName`)}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="lastName"
            placeholder="Last name"
            {...register(`lastName`)}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="phone"
            placeholder="Phone"
            {...register(`phone`)}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select {...register("gender")} id="gender" className="form-control">
            <option>Gender</option>
            {["Male", "Female"].map((g) => (
              <option key={g} value={g} selected={g === teacher.gender}>
                {g}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default EditTeacher;
