import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaPlus } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  ENDPOINTS,
  httpService,
  PaginatedResult,
  PagingOptions,
} from "../../api";
import Paginate from "../../components/common/paginate";
import AddStudentExam from "../../components/forms/addStudentExam";
import Delete from "../../components/forms/delete";
import EditStudentExam from "../../components/forms/editStudentExam";
import { StudentExam, TableHeader } from "@models";

const headers: TableHeader[] = [
  { id: 1, name: "Exam", align: "left" },
  { id: 2, name: "Student", align: "left" },
  { id: 3, name: "Subject", align: "left" },
  { id: 4, name: "Marks", align: "left" },
  { id: 5, name: "Actions", align: "center" },
];

const StudentExamsTable = () => {
  const [studentExams, setStudentExams] = useState<StudentExam[]>([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(4);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const toggle = withReactContent(Swal);

  useEffect(() => {
    setLoading(true);

    (async function () {
      const options = new PagingOptions(page, perPage);
      const res = await httpService<StudentExam>(
        ENDPOINTS.StudentExams,
        options
      ).getAll();

      const {
        items,
        page: currentPage,
        size,
        totalPages,
      } = res.data as PaginatedResult<StudentExam>;

      setStudentExams(items);
      setPage(currentPage);
      setPerPage(size);
      setTotalPages(totalPages);
      setLoading(false);
    })();
  }, [page, perPage, reload]);

  const onCreateStudentExam = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "50%",
      html: <AddStudentExam reload={reload} setReload={setReload} />,
    });
  };

  const handleDelete = async (id: number) => {
    await httpService(ENDPOINTS.StudentExams).delete(id);
    setReload(!reload);
  };

  const onDelete = (id: number) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <Delete id={id} onDelete={handleDelete} />,
    });
  };

  const onEditStudentExam = (studentExam: StudentExam) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "50%",
      html: (
        <EditStudentExam
          studentExam={studentExam}
          reload={reload}
          setReload={setReload}
        />
      ),
    });
  };

  return (
    <div className="widget-content widget-content-area mt-4 w-70">
      <div className="d-flex align-items-space-between align-content-center">
        <div style={{ flex: 1 }}>
          <div>
            <button
              className="btn btn-success mr-2"
              onClick={() => onCreateStudentExam()}
            >
              <FaPlus /> Create Student Exam
            </button>
          </div>

          <div className="table-responsive mb-4 px-2 mt-4">
            <table className="table w-100">
              <thead>
                <tr>
                  {headers.map((h) => (
                    <th
                      className={`${
                        h.align === "left" ? "text-left" : "text-center"
                      }`}
                      key={h.id}
                    >
                      {h.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {studentExams.length > 0 &&
                  studentExams.map((s) => {
                    return (
                      <tr key={s.id}>
                        <td>{s.exam?.name}</td>
                        <td>{`${s.student?.firstName} ${s.student?.lastName}`}</td>
                        <td>{s.subject?.name}</td>
                        <td>{s.marks}</td>
                        <td className="text-center">
                          <button
                            className="btn btn-success btn-sm"
                            onClick={() => onEditStudentExam(s)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-danger btn-sm ml-2"
                            onClick={() => onDelete(s.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="w-75">
              <Paginate
                canPreviousPage={page > 0 && true}
                canNextPage={totalPages !== page && true}
                currentPage={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerPage}
                totalPage={totalPages}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentExamsTable;
