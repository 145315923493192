export const SURAH_DATA = [
  {
    id: 1,
    value: "Al-Faatiha (سُورَةُ ٱلْفَاتِحَةِ)",
    label: "Al-Faatiha (سُورَةُ ٱلْفَاتِحَةِ)",
  },
  {
    id: 2,
    value: "Al-Baqara (سُورَةُ البَقَرَةِ)",
    label: "Al-Baqara (سُورَةُ البَقَرَةِ)",
  },
  {
    id: 3,
    value: "Aal-i-Imraan (سُورَةُ آلِ عِمۡرَانَ)",
    label: "Aal-i-Imraan (سُورَةُ آلِ عِمۡرَانَ)",
  },
  {
    id: 4,
    value: "An-Nisaa (سُورَةُ النِّسَاءِ)",
    label: "An-Nisaa (سُورَةُ النِّسَاءِ)",
  },
  {
    id: 5,
    value: "Al-Maaida (سُورَةُ المَائـِدَةِ)",
    label: "Al-Maaida (سُورَةُ المَائـِدَةِ)",
  },
  {
    id: 6,
    value: "Al-An'aam (سُورَةُ الأَنۡعَامِ)",
    label: "Al-An'aam (سُورَةُ الأَنۡعَامِ)",
  },
  {
    id: 7,
    value: "Al-A'raaf (سُورَةُ الأَعۡرَافِ)",
    label: "Al-A'raaf (سُورَةُ الأَعۡرَافِ)",
  },
  {
    id: 8,
    value: "Al-Anfaal (سُورَةُ الأَنفَالِ)",
    label: "Al-Anfaal (سُورَةُ الأَنفَالِ)",
  },
  {
    id: 9,
    value: "At-Tawba (سُورَةُ التَّوۡبَةِ)",
    label: "At-Tawba (سُورَةُ التَّوۡبَةِ)",
  },
  {
    id: 10,
    value: "Yunus (سُورَةُ يُونُسَ)",
    label: "Yunus (سُورَةُ يُونُسَ)",
  },
  {
    id: 11,
    value: "Hud (سُورَةُ هُودٍ)",
    label: "Hud (سُورَةُ هُودٍ)",
  },
  {
    id: 12,
    value: "Yusuf (سُورَةُ يُوسُفَ)",
    label: "Yusuf (سُورَةُ يُوسُفَ)",
  },
  {
    id: 13,
    value: "Ar-Ra'd (سُورَةُ الرَّعۡدِ)",
    label: "Ar-Ra'd (سُورَةُ الرَّعۡدِ)",
  },
  {
    id: 14,
    value: "Ibrahim (سُورَةُ إِبۡرَاهِيمَ)",
    label: "Ibrahim (سُورَةُ إِبۡرَاهِيمَ)",
  },
  {
    id: 15,
    value: "Al-Hijr (سُورَةُ الحِجۡرِ)",
    label: "Al-Hijr (سُورَةُ الحِجۡرِ)",
  },
  {
    id: 16,
    value: "An-Nahl (سُورَةُ النَّحۡلِ)",
    label: "An-Nahl (سُورَةُ النَّحۡلِ)",
  },
  {
    id: 17,
    value: "Al-Israa (سُورَةُ الإِسۡرَاءِ)",
    label: "Al-Israa (سُورَةُ الإِسۡرَاءِ)",
  },
  {
    id: 18,
    value: "Al-Kahf (سُورَةُ الكَهۡفِ)",
    label: "Al-Kahf (سُورَةُ الكَهۡفِ)",
  },
  {
    id: 19,
    value: "Maryam (سُورَةُ مَرۡيَمَ)",
    label: "Maryam (سُورَةُ مَرۡيَمَ)",
  },
  {
    id: 20,
    value: "Taa-Haa (سُورَةُ طه)",
    label: "Taa-Haa (سُورَةُ طه)",
  },

  {
    id: 21,
    value: "Al-Anbiyaa (سُورَةُ الأَنبِيَاءِ)",
    label: "Al-Anbiyaa (سُورَةُ الأَنبِيَاءِ)",
  },
  {
    id: 22,
    value: "Al-Hajj (سُورَةُ الحَجِّ)",
    label: "Al-Hajj (سُورَةُ الحَجِّ)",
  },
  {
    id: 23,
    value: "Al-Muminoon (سُورَةُ المُؤۡمِنُونَ)",
    label: "Al-Muminoon (سُورَةُ المُؤۡمِنُونَ)",
  },
  {
    id: 24,
    value: "An-Noor (سُورَةُ النُّورِ)",
    label: "An-Noor (سُورَةُ النُّورِ)",
  },
  {
    id: 25,
    value: "Al-Furqaan (سُورَةُ الفُرۡقَانِ)",
    label: "Al-Furqaan (سُورَةُ الفُرۡقَانِ)",
  },
  {
    id: 26,
    value: "Ash-Shu'araa (سُورَةُ الشُّعَرَاءِ)",
    label: "Ash-Shu'araa (سُورَةُ الشُّعَرَاءِ)",
  },
  {
    id: 27,
    value: "An-Naml (سُورَةُ النَّمۡلِ)",
    label: "An-Naml (سُورَةُ النَّمۡلِ)",
  },
  {
    id: 28,
    value: "Al-Qasas (سُورَةُ القَصَصِ)",
    label: "Al-Qasas (سُورَةُ القَصَصِ)",
  },
  {
    id: 29,
    value: "Al-Ankaboot (سُورَةُ العَنكَبُوتِ)",
    label: "Al-Ankaboot (سُورَةُ العَنكَبُوتِ)",
  },
  {
    id: 30,
    value: "Ar-Room (سُورَةُ الرُّومِ)",
    label: "Ar-Room (سُورَةُ الرُّومِ)",
  },
  {
    id: 31,
    value: "Luqman (سُورَةُ لُقۡمَانَ)",
    label: "Luqman (سُورَةُ لُقۡمَانَ)",
  },
  {
    id: 32,
    value: "As-Sajda (سُورَةُ السَّجۡدَةِ)",
    label: "As-Sajda (سُورَةُ السَّجۡدَةِ)",
  },
  {
    id: 33,
    value: "Al-Ahzaab (سُورَةُ الأَحۡزَابِ)",
    label: "Al-Ahzaab (سُورَةُ الأَحۡزَابِ)",
  },
  { id: 34, value: "Saba (سُورَةُ سَبَإٍ)", label: "Saba (سُورَةُ سَبَإٍ)" },
  {
    id: 35,
    value: "Faatir (سُورَةُ فَاطِرٍ)",
    label: "Faatir (سُورَةُ فَاطِرٍ)",
  },
  { id: 36, value: "Yaseen (سُورَةُ يسٓ)", label: "Yaseen (سُورَةُ يسٓ)" },
  {
    id: 37,
    value: "As-Saaffaat (سُورَةُ الصَّافَّاتِ)",
    label: "As-Saaffaat (سُورَةُ الصَّافَّاتِ)",
  },
  { id: 38, value: "Saad (سُورَةُ صٓ)", label: "Saad (سُورَةُ صٓ)" },
  {
    id: 39,
    value: "Az-Zumar (سُورَةُ الزُّمَرِ)",
    label: "Az-Zumar (سُورَةُ الزُّمَرِ)",
  },
  {
    id: 40,
    value: "Ghafir (سُورَةُ غَافِرٍ)",
    label: "Ghafir (سُورَةُ غَافِرٍ)",
  },
  {
    id: 41,
    value: "Fussilat (سُورَةُ فُصِّلَتۡ)",
    label: "Fussilat (سُورَةُ فُصِّلَتۡ)",
  },
  {
    id: 42,
    value: "Ash-Shura (سُورَةُ الشُّورَىٰ)",
    label: "Ash-Shura (سُورَةُ الشُّورَىٰ)",
  },
  {
    id: 43,
    value: "Az-Zukhruf (سُورَةُ الزُّخۡرُفِ)",
    label: "Az-Zukhruf (سُورَةُ الزُّخۡرُفِ)",
  },
  {
    id: 44,
    value: "Ad-Dukhaan (سُورَةُ الدُّخَانِ)",
    label: "Ad-Dukhaan (سُورَةُ الدُّخَانِ)",
  },
  {
    id: 45,
    value: "Al-Jaathiya (سُورَةُ الجَاثِيَةِ)",
    label: "Al-Jaathiya (سُورَةُ الجَاثِيَةِ)",
  },
  {
    id: 46,
    value: "Al-Ahqaf (سُورَةُ الأَحۡقَافِ)",
    label: "Al-Ahqaf (سُورَةُ الأَحۡقَافِ)",
  },
  {
    id: 47,
    value: "Muhammad (سُورَةُ مُحَمَّدٍ)",
    label: "Muhammad (سُورَةُ مُحَمَّدٍ)",
  },
  {
    id: 48,
    value: "Al-Fath (سُورَةُ الفَتۡحِ)",
    label: "Al-Fath (سُورَةُ الفَتۡحِ)",
  },
  {
    id: 49,
    value: "Al-Hujuraat (سُورَةُ الحُجُرَاتِ)",
    label: "Al-Hujuraat (سُورَةُ الحُجُرَاتِ)",
  },
  { id: 50, value: "Qaaf (سُورَةُ قٓ)", label: "Qaaf (سُورَةُ قٓ)" },
  {
    id: 51,
    value: "Adh-Dhaariyat (سُورَةُ الذَّارِيَاتِ)",
    label: "Adh-Dhaariyat (سُورَةُ الذَّارِيَاتِ)",
  },
  {
    id: 52,
    value: "At-Tur (سُورَةُ الطُّورِ)",
    label: "At-Tur (سُورَةُ الطُّورِ)",
  },
  {
    id: 53,
    value: "An-Najm (سُورَةُ النَّجۡمِ)",
    label: "An-Najm (سُورَةُ النَّجۡمِ)",
  },
  {
    id: 54,
    value: "Al-Qamar (سُورَةُ القَمَرِ)",
    label: "Al-Qamar (سُورَةُ القَمَرِ)",
  },
  {
    id: 55,
    value: "Ar-Rahmaan (سُورَةُ الرَّحۡمَٰن)",
    label: "Ar-Rahmaan (سُورَةُ الرَّحۡمَٰن)",
  },
  {
    id: 56,
    value: "Al-Waaqia (سُورَةُ الوَاقِعَةِ)",
    label: "Al-Waaqia (سُورَةُ الوَاقِعَةِ)",
  },
  {
    id: 57,
    value: "Al-Hadid (سُورَةُ الحَدِيدِ)",
    label: "Al-Hadid (سُورَةُ الحَدِيدِ)",
  },
  {
    id: 58,
    value: "Al-Mujaadila (سُورَةُ المُجَادلَةِ)",
    label: "Al-Mujaadila (سُورَةُ المُجَادلَةِ)",
  },
  {
    id: 59,
    value: "Al-Hashr (سُورَةُ الحَشۡرِ)",
    label: "Al-Hashr (سُورَةُ الحَشۡرِ)",
  },
  {
    id: 60,
    value: "Al-Mumtahana (سُورَةُ المُمۡتَحنَةِ)",
    label: "Al-Mumtahana (سُورَةُ المُمۡتَحنَةِ)",
  },
  {
    id: 61,
    value: "As-Saff (سُورَةُ الصَّفِّ)",
    label: "As-Saff (سُورَةُ الصَّفِّ)",
  },
  {
    id: 62,
    value: "Al-Jumu'a (سُورَةُ الجُمُعَةِ)",
    label: "Al-Jumu'a (سُورَةُ الجُمُعَةِ)",
  },
  {
    id: 63,
    value: "Al-Munaafiqoon (سُورَةُ المُنَافِقُونَ)",
    label: "Al-Munaafiqoon (سُورَةُ المُنَافِقُونَ)",
  },
  {
    id: 64,
    value: "At-Taghaabun (سُورَةُ التَّغَابُنِ)",
    label: "At-Taghaabun (سُورَةُ التَّغَابُنِ)",
  },
  {
    id: 65,
    value: "At-Talaaq (سُورَةُ الطَّلَاقِ)",
    label: "At-Talaaq (سُورَةُ الطَّلَاقِ)",
  },
  {
    id: 66,
    value: "At-Tahrim (سُورَةُ التَّحۡرِيمِ)",
    label: "At-Tahrim (سُورَةُ التَّحۡرِيمِ)",
  },
  {
    id: 67,
    value: "Al-Mulk (سُورَةُ المُلۡكِ)",
    label: "Al-Mulk (سُورَةُ المُلۡكِ)",
  },
  {
    id: 68,
    value: "Al-Qalam (سُورَةُ القَلَمِ)",
    label: "Al-Qalam (سُورَةُ القَلَمِ)",
  },
  {
    id: 69,
    value: "Al-Haaqqa (سُورَةُ الحَاقَّةِ)",
    label: "Al-Haaqqa (سُورَةُ الحَاقَّةِ)",
  },
  {
    id: 70,
    value: "Al-Ma'aarij (سُورَةُ المَعَارِجِ)",
    label: "Al-Ma'aarij (سُورَةُ المَعَارِجِ)",
  },
  { id: 71, value: "Nooh (سُورَةُ نُوحٍ)", label: "Nooh (سُورَةُ نُوحٍ)" },
  {
    id: 72,
    value: "Al-Jinn (سُورَةُ الجِنِّ)",
    label: "Al-Jinn (سُورَةُ الجِنِّ)",
  },
  {
    id: 73,
    value: "Al-Muzzammil (سُورَةُ المُزَّمِّلِ)",
    label: "Al-Muzzammil (سُورَةُ المُزَّمِّلِ)",
  },
  {
    id: 74,
    value: "Al-Muddaththir (سُورَةُ المُدَّثِّرِ)",
    label: "Al-Muddaththir (سُورَةُ المُدَّثِّرِ)",
  },
  {
    id: 75,
    value: "Al-Qiyaama (سُورَةُ القِيَامَةِ)",
    label: "Al-Qiyaama (سُورَةُ القِيَامَةِ)",
  },
  {
    id: 76,
    value: "Al-Insaan (سُورَةُ الإِنسَانِ)",
    label: "Al-Insaan (سُورَةُ الإِنسَانِ)",
  },
  {
    id: 77,
    value: "Al-Mursalaat (سُورَةُ المُرۡسَلَاتِ)",
    label: "Al-Mursalaat (سُورَةُ المُرۡسَلَاتِ)",
  },
  {
    id: 78,
    value: "An-Naba (سُورَةُ النَّبَإِ)",
    label: "An-Naba (سُورَةُ النَّبَإِ)",
  },
  {
    id: 79,
    value: "An-Naazi'aat (سُورَةُ النَّازِعَاتِ)",
    label: "An-Naazi'aat (سُورَةُ النَّازِعَاتِ)",
  },
  { id: 80, value: "Abasa (سُورَةُ عَبَسَ)", label: "Abasa (سُورَةُ عَبَسَ)" },
  {
    id: 81,
    value: "At-Takwir (سُورَةُ التَّكۡوِيرِ)",
    label: "At-Takwir (سُورَةُ التَّكۡوِيرِ)",
  },
  {
    id: 82,
    value: "Al-Infitaar (سُورَةُ الانفِطَارِ)",
    label: "Al-Infitaar (سُورَةُ الانفِطَارِ)",
  },
  {
    id: 83,
    value: "Al-Mutaffifin (سُورَةُ المُطَفِّفِينَ)",
    label: "Al-Mutaffifin (سُورَةُ المُطَفِّفِينَ)",
  },
  {
    id: 84,
    value: "Al-Inshiqaaq (سُورَةُ الانشِقَاقِ)",
    label: "Al-Inshiqaaq (سُورَةُ الانشِقَاقِ)",
  },
  {
    id: 85,
    value: "Al-Burooj (سُورَةُ البُرُوجِ)",
    label: "Al-Burooj (سُورَةُ البُرُوجِ)",
  },
  {
    id: 86,
    value: "At-Taariq (سُورَةُ الطَّارِقِ)",
    label: "At-Taariq (سُورَةُ الطَّارِقِ)",
  },
  {
    id: 87,
    value: "Al-A'laa (سُورَةُ الأَعۡلَىٰ)",
    label: "Al-A'laa (سُورَةُ الأَعۡلَىٰ)",
  },
  {
    id: 88,
    value: "Al-Ghaashiya (سُورَةُ الغَاشِيَةِ)",
    label: "Al-Ghaashiya (سُورَةُ الغَاشِيَةِ)",
  },
  {
    id: 89,
    value: "Al-Fajr (سُورَةُ الفَجۡرِ)",
    label: "Al-Fajr (سُورَةُ الفَجۡرِ)",
  },
  {
    id: 90,
    value: "Al-Balad (سُورَةُ البَلَدِ)",
    label: "Al-Balad (سُورَةُ البَلَدِ)",
  },
  {
    id: 91,
    value: "Ash-Shams (سُورَةُ الشَّمۡسِ)",
    label: "Ash-Shams (سُورَةُ الشَّمۡسِ)",
  },
  {
    id: 92,
    value: "Al-Lail (سُورَةُ اللَّيۡلِ)",
    label: "Al-Lail (سُورَةُ اللَّيۡلِ)",
  },
  {
    id: 93,
    value: "Ad-Dhuhaa (سُورَةُ الضُّحَىٰ)",
    label: "Ad-Dhuhaa (سُورَةُ الضُّحَىٰ)",
  },
  {
    id: 94,
    value: "Ash-Sharh (سُورَةُ الشَّرۡحِ)",
    label: "Ash-Sharh (سُورَةُ الشَّرۡحِ)",
  },
  {
    id: 95,
    value: "At-Tin (سُورَةُ التِّينِ)",
    label: "At-Tin (سُورَةُ التِّينِ)",
  },
  {
    id: 96,
    value: "Al-Alaq (سُورَةُ العَلَقِ)",
    label: "Al-Alaq (سُورَةُ العَلَقِ)",
  },
  {
    id: 97,
    value: "Al-Qadr (سُورَةُ القَدۡرِ)",
    label: "Al-Qadr (سُورَةُ القَدۡرِ)",
  },
  {
    id: 98,
    value: "Al-Bayyina (سُورَةُ البَيِّنَةِ)",
    label: "Al-Bayyina (سُورَةُ البَيِّنَةِ)",
  },
  {
    id: 99,
    value: "Az-Zalzala (سُورَةُ الزَّلۡزَلَةِ)",
    label: "Az-Zalzala (سُورَةُ الزَّلۡزَلَةِ)",
  },
  {
    id: 100,
    value: "Al-Aadiyaat (سُورَةُ العَادِيَاتِ)",
    label: "Al-Aadiyaat (سُورَةُ العَادِيَاتِ)",
  },
  {
    id: 101,
    value: "Al-Qaari'a (سُورَةُ القَارِعَةِ)",
    label: "Al-Qaari'a (سُورَةُ القَارِعَةِ)",
  },
  {
    id: 102,
    value: "At-Takaathur (سُورَةُ التَّكَاثُرِ)",
    label: "At-Takaathur (سُورَةُ التَّكَاثُرِ)",
  },
  {
    id: 103,
    value: "Al-Asr (سُورَةُ العَصۡرِ)",
    label: "Al-Asr (سُورَةُ العَصۡرِ)",
  },
  {
    id: 104,
    value: "Al-Humaza (سُورَةُ الهُمَزَةِ)",
    label: "Al-Humaza (سُورَةُ الهُمَزَةِ)",
  },
  {
    id: 105,
    value: "Al-Fil (سُورَةُ الفِيلِ)",
    label: "Al-Fil (سُورَةُ الفِيلِ)",
  },
  {
    id: 106,
    value: "Quraish (سُورَةُ قُرَيۡشٍ)",
    label: "Quraish (سُورَةُ قُرَيۡشٍ)",
  },
  {
    id: 107,
    value: "Al-Maa'un (سُورَةُ المَاعُونِ)",
    label: "Al-Maa'un (سُورَةُ المَاعُونِ)",
  },
  {
    id: 108,
    value: "Al-Kawthar (سُورَةُ الكَوۡثَرِ)",
    label: "Al-Kawthar (سُورَةُ الكَوۡثَرِ)",
  },
  {
    id: 109,
    value: "Al-Kaafiroon (سُورَةُ الكَافِرُونَ)",
    label: "Al-Kaafiroon (سُورَةُ الكَافِرُونَ)",
  },
  {
    id: 110,
    value: "An-Nasr (سُورَةُ النَّصۡرِ)",
    label: "An-Nasr (سُورَةُ النَّصۡرِ)",
  },
  {
    id: 111,
    value: "Al-Masad (سُورَةُ المَسَدِ)",
    label: "Al-Masad (سُورَةُ المَسَدِ)",
  },
  {
    id: 112,
    value: "Al-Ikhlaas (سُورَةُ الإِخۡلَاصِ)",
    label: "Al-Ikhlaas (سُورَةُ الإِخۡلَاصِ)",
  },
  {
    id: 113,
    value: "Al-Falaq (سُورَةُ الفَلَقِ)",
    label: "Al-Falaq (سُورَةُ الفَلَقِ)",
  },
  {
    id: 114,
    value: "An-Naas (سُورَةُ النَّاسِ)",
    label: "An-Naas (سُورَةُ النَّاسِ)",
  },
];

export const HIGAAD_DATA = [
  { value: "حروف الهجاء ", label: "حروف الهجاء " },
  { value: "الحركات", label: "الحركات" },
  { value: "المــدود", label: "المــدود" },
  { value: "التنوين", label: "التنوين" },
  { value: "السُّــكون", label: "السُّــكون" },
  { value: "الشّـدّة", label: "الشّـدّة" },
  { value: "تدريب قراءة القران", label: "تدريب قراءة القران" },
];

export const TYPE_HIGAAD = "Higaad";
