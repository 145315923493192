export class UserInfoViewModel {
  userId: number = 0;
  fullName: string = "";
  userName = "";
  // roleId = 0;
  roleName = "";
  //   isSUDO = false;
  // userType: UserType;
  rcdCode: string = "";
  email: string;
  classId: number;
  token = "";
}
