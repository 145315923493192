import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { BsPlusSquareFill, BsXSquareFill } from "react-icons/bs";
import { ENDPOINTS, httpService } from "@api";
import Select from "react-select";
import { Milestones } from "@models";
import { HIGAAD_DATA, SURAH_DATA, TYPE_HIGAAD } from "@components";

type Props = {
  data: { id: number; milestones: Milestones[] };
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditStudentMilestone = ({ data, reload, setReload }: Props) => {
  const validationSchema = Yup.object()
    .shape({
      milestones: Yup.array(
        Yup.object().shape({
          subject: Yup.string().required("Name is required"),
          semester: Yup.string().required("Semester is required"),
          target: Yup.string().required("Target is required"),
          current: Yup.string().required("Current is required"),
          achieved: Yup.string().required("Achieved is required"),
          absence: Yup.string().required("Absence is required"),
          examResult: Yup.string().required("Exam Result is required"),
        })
      ),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      milestones: data.milestones.map((m: Milestones) => ({
        subject: m.subject,
        semester: m.semester,
        target: m.target,
        current: m.current,
        achieved: m.achieved,
        absence: m.absence,
        examResult: m.examResult,
      })),
    },
  };

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "milestones", // unique name for the Field Array
  });

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.StudentsMilestone).update(data.id, values);
    setReload(!reload);
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4 mx-4"
    >
      {fields.map((item: any, idx) => {
        const selectedSubject = watch(`milestones.${idx}.subject`);

        return (
          <div className="row my-4 w-100">
            <div className="row">
              <div className="col-sm-3 col-md-3 col-lg-3 py-3">
                <select
                  {...register(`milestones.${idx}.subject`, {
                    required: "name is required",
                  })}
                  id="name"
                  className="form-control"
                >
                  <option value="">Select Subject</option>
                  {["Quran (hifdi)", "Quran (Qira'ah)", "Higaad"].map((g) => (
                    <option key={g} value={g}>
                      {g}
                    </option>
                  ))}
                </select>{" "}
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 py-3">
                <select
                  {...register(`milestones.${idx}.semester`, {
                    required: "name is required",
                  })}
                  id="name"
                  className="form-control"
                >
                  <option value="">Select Semester</option>
                  {["1st Semester", "2nd Semester"].map((g) => (
                    <option key={g} value={g} disabled={g === item.name}>
                      {g}
                    </option>
                  ))}
                </select>{" "}
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 py-3">
                <Controller
                  name={`milestones.${idx}.current`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={
                        selectedSubject !== TYPE_HIGAAD
                          ? SURAH_DATA
                          : HIGAAD_DATA
                      }
                      defaultValue={
                        selectedSubject !== TYPE_HIGAAD
                          ? SURAH_DATA.find(
                              (option) => option.value === field.value
                            )
                          : HIGAAD_DATA.find(
                              (option) => option.value === field.value
                            )
                      }
                      isSearchable={true}
                      placeholder={`Search ${
                        selectedSubject !== TYPE_HIGAAD ? "surah" : "higaad"
                      }`}
                      // To handle the "value" correctly for react-select
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption?.value)
                      }
                      // react-hook-form expects the value, but react-select uses an object with 'value' and 'label'
                      value={
                        selectedSubject !== TYPE_HIGAAD
                          ? SURAH_DATA.find(
                              (option) => option.value === field.value
                            )
                          : HIGAAD_DATA.find(
                              (option) => option.value === field.value
                            )
                      }
                    />
                  )}
                  rules={{ required: "This field is required" }}
                />
                {/* <input
                  type="text"
                  className="form-control mr-2"
                  id="name"
                  placeholder="Current"
                  {...register(`milestones.${idx}.current`, {
                    required: "current is required",
                  })}
                /> */}
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3 py-3">
                <Controller
                  name={`milestones.${idx}.target`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={
                        selectedSubject !== TYPE_HIGAAD
                          ? SURAH_DATA
                          : HIGAAD_DATA
                      }
                      isSearchable={true}
                      placeholder={`Search ${
                        selectedSubject !== TYPE_HIGAAD ? "surah" : "higaad"
                      }`}
                      // To handle the "value" correctly for react-select
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption?.value)
                      }
                      defaultValue={
                        selectedSubject !== TYPE_HIGAAD
                          ? SURAH_DATA.find(
                              (option) => option.value === field.value
                            )
                          : HIGAAD_DATA.find(
                              (option) => option.value === field.value
                            )
                      }
                      // react-hook-form expects the value, but react-select uses an object with 'value' and 'label'
                      value={
                        selectedSubject !== TYPE_HIGAAD
                          ? SURAH_DATA.find(
                              (option) => option.value === field.value
                            )
                          : HIGAAD_DATA.find(
                              (option) => option.value === field.value
                            )
                      }
                    />
                  )}
                  rules={{ required: "This field is required" }}
                />

                {/* <input
                  type="text"
                  className="form-control mr-2"
                  id="target"
                  placeholder="Target"
                  {...register(`milestones.${idx}.target`, {
                    required: "Target is required",
                  })}
                /> */}
              </div>
            </div>{" "}
            <div className="col-sm-3 col-md-3 col-lg-3 py-3">
              {/* <input
                  type="text"
                  className="form-control mr-2"
                  id="achieved"
                  placeholder="Achieved"
                  {...register(`milestones.${idx}.achieved`, {
                    required: "Achieved is required",
                  })}
                /> */}
              <Controller
                name={`milestones.${idx}.achieved`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={
                      selectedSubject !== TYPE_HIGAAD ? SURAH_DATA : HIGAAD_DATA
                    }
                    defaultValue={
                      selectedSubject !== TYPE_HIGAAD
                        ? SURAH_DATA.find(
                            (option) => option.value === field.value
                          )
                        : HIGAAD_DATA.find(
                            (option) => option.value === field.value
                          )
                    }
                    isSearchable={true}
                    placeholder={`Search ${
                      selectedSubject !== TYPE_HIGAAD ? "surah" : "higaad"
                    }`}
                    // To handle the "value" correctly for react-select
                    onChange={(selectedOption) =>
                      field.onChange(selectedOption?.value)
                    }
                    // react-hook-form expects the value, but react-select uses an object with 'value' and 'label'
                    value={
                      selectedSubject !== TYPE_HIGAAD
                        ? SURAH_DATA.find(
                            (option) => option.value === field.value
                          )
                        : HIGAAD_DATA.find(
                            (option) => option.value === field.value
                          )
                    }
                  />
                )}
                rules={{ required: "This field is required" }}
              />
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2 py-3">
              <input
                type="text"
                className="form-control mr-2"
                id="absence"
                placeholder="Absence"
                {...register(`milestones.${idx}.absence`, {
                  required: "Absence is required",
                })}
              />
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2 py-3 d-flex">
              <input
                type="text"
                className="form-control mr-2"
                id="examResult"
                placeholder="Exam Result"
                {...register(`milestones.${idx}.examResult`, {
                  required: "Exam Result is required",
                })}
              />

              <div className="col-sm-3 col-md-3 col-lg-3 d-flex align-items-center justify-content-center">
                <BsXSquareFill
                  style={{
                    cursor: "pointer",
                    color: "#e7515a",
                  }}
                  fontSize="30px"
                  onClick={() => remove(idx)}
                />
              </div>
            </div>
          </div>
        );
      })}

      <div style={{ alignSelf: "flex-start" }} className="mt-2">
        <BsPlusSquareFill
          style={{ cursor: "pointer" }}
          fontSize="30px"
          onClick={() => {
            append({
              subject: "",
              semester: "",
              target: "",
              current: "",
              achieved: "",
              absence: "",
              examResult: "",
            });
          }}
        />
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Update
        </button>
      </div>
    </form>
  );
};

export default EditStudentMilestone;
