import { ENDPOINTS, httpService } from "@api";
import { useState } from "react";
import DatePicker from "react-datepicker";

type Props = {
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

function CreateMonthlyAttendance({ reload, setReload }: Props) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    // get month and year from date
    if (!selectedDate) return;
    setLoading(true);

    let year = selectedDate?.getFullYear();
    let month = selectedDate?.getMonth() + 1;

    console.log("year", selectedDate?.getFullYear());
    console.log("month", selectedDate?.getMonth());

    await httpService(ENDPOINTS.MonthlyAttendance).post({
      month: month,
      year: year,
    });

    setReload(!reload);
  };

  return (
    <main className="d-flex flex-column w-100 mt-4">
      <div className="row w-100">
        <div className="col-sm-12 col-md-12 col-lg-12 ">
          <DatePicker
            className="custom-select"
            onChange={(e) => {
              setSelectedDate(e);
            }}
            selected={selectedDate}
            dateFormat="MM"
            showMonthYearPicker
            inline
          />
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-primary mt-3 align-self-end"
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          Create
        </button>
      </div>
    </main>
  );
}

export default CreateMonthlyAttendance;
