import { useEffect, useState } from "react";
import { ENDPOINTS, httpService } from "../../api";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";

interface ClassFamilies {
  id: number;
  name: string;
  families: [
    { id: number; name: string; students: { id: number; name: string }[] }
  ];
}

type Props = {
  onFamilyChange: (id: number) => void;
  onClassChange: (id: number) => void;
  onStudentChange: (id: number) => void;
};

const ClassFamilyStudentFilter = ({
  onFamilyChange,
  onClassChange,
  onStudentChange,
}: Props) => {
  const [classes, setClasses] = useState<ClassFamilies[]>([]);
  const [families, setFamilies] = useState<
    {
      id: number;
      name: string;
      students: {
        id: number;
        name: string;
      }[];
    }[]
  >([]);
  const [students, setStudents] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);

  useEffect(() => {
    (async () => {
      const res = await httpService(ENDPOINTS.GetClassFamilyStudents).getAll();

      const data = res.data ?? [];

      setClasses(
        data.length > 0
          ? data.map((item: any) => {
              return {
                id: item.id,
                name: item.name,
                families: JSON.parse(item.families) as {
                  id: number;
                  name: string;
                  students: { id: number; name: string }[];
                }[],
              };
            })
          : []
      );
    })();

    return () => {};
  }, []);

  const onChangeClass = (e: any) => {
    onClassChange(+e.target.value);
    setFamilies(classes.find((c) => c.id === +e.target.value)?.families ?? []);
  };

  const onChangeFamily = (e: any) => {
    onFamilyChange(+e.target.value);
    setStudents(families.find((c) => c.id === +e.target.value)?.students ?? []);
  };

  const onChangeStudent = (e: any) => {
    onStudentChange(+e.target.value);
  };

  return (
    <div className="row mx-4">
      {" "}
      <div className="row form-group mr-4">
        <div className="form-group">
          <select
            id="familyId"
            className="form-control form-control-sm"
            onChange={onChangeClass}
          >
            <option value="all">All Classes</option>
            {classes.map((c) => {
              return (
                <option key={c.id} value={c.id}>
                  {toUpperCaseFirstLetter(c.name)}
                </option>
              );
            })}
          </select>
        </div>
      </div>{" "}
      <div className="form-group mr-4">
        <div className="form-group">
          <select
            id="familyId"
            className="form-control form-control-sm"
            onChange={onChangeFamily}
          >
            <option value="all">Select Family</option>
            {families.map((f) => {
              return (
                <option key={f.id} value={f.id}>
                  {toUpperCaseFirstLetter(f.name)}
                </option>
              );
            })}
          </select>
        </div>
      </div>{" "}
      <div className="form-group">
        <div className="form-group">
          <select
            id="familyId"
            className="form-control form-control-sm"
            onChange={onChangeStudent}
          >
            <option value="all">Select Student</option>
            {students.map((s) => {
              return (
                <option key={s.id} value={s.id}>
                  {toUpperCaseFirstLetter(s.name)}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

export default ClassFamilyStudentFilter;
