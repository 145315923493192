export enum RoleType {
  ADMIN = "Admin",
  Staff = "Staff",
  INSTRUCTOR = "Instructor",
}

export enum AssetStatus {
  ACTIVE,
  DEPRECIATED,
}
