import { useRef, useState } from "react";
import { FaPrint } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import { ENDPOINTS, PagingOptions, httpService } from "../../api";
import FilterByClass from "../../components/dropdowns/filterByClass";
import { Student } from "@models";
import FilterByFamily from "../../components/dropdowns/filterByFamily";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import FilterDataColumns from "./filterColumn";
import { StudentsReportTable } from "./StudentsReportTable";

const StudentsReport = () => {
  const componentRef = useRef(null);
  const [students, setStudents] = useState<Student[]>([]);
  const [classId, setClassId] = useState<number>();
  const [familyId, setFamilyId] = useState<number>();
  const [isGenerating, setIsGenerating] = useState(false);

  const toggle = withReactContent(Swal);

  const onClassChange = (value: { id: string; name: string }) => {
    setClassId(parseInt(value.id));
  };

  const onFamilyChange = (value: any) => {
    setFamilyId(parseInt(value));
  };

  const onGenerate = async () => {
    setIsGenerating(true);

    const options = new PagingOptions();

    if (classId) {
      options.filter<Student>((s) => s.eq("classId", classId));
    }

    if (familyId) {
      options.filter<Student>((s) => s.eq("familyId", familyId));
    }

    const res = await httpService(ENDPOINTS.StudentList, options).getAll();

    setStudents(res.data ?? []);

    setIsGenerating(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onExport = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "20%",
      html: <FilterDataColumns students={students} />,
    });
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex align-items-start">
        <span className="mt-1">
          <FilterByClass onHandleChange={onClassChange} />
        </span>
        <span className="mt-1 ml-2">
          <FilterByFamily onHandleChange={onFamilyChange} />
        </span>
        <span className="mx-2 mt-1">
          <button className="btn btn-success" onClick={onGenerate}>
            {isGenerating && (
              <span className="spinner-border spinner-grow-sm text-white mr-2" />
            )}
            Generate
          </button>
        </span>

        <span className="mt-1">
          <button
            onClick={() => onExport()}
            className="btn btn-success"
            disabled={!students.length}
          >
            <span className="">Export as CSV</span>
          </button>
        </span>
        <span className="ml-2 mt-1">
          <button
            className="btn btn-primary"
            onClick={handlePrint}
            disabled={students.length ? false : true}
          >
            <FaPrint /> Print
          </button>
        </span>
      </div>
      <StudentsReportTable students={students} ref={componentRef} />
    </div>
  );
};

export default StudentsReport;
