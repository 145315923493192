import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { User } from "@models";
import { ENDPOINTS, httpService } from "../../api";

type Props = {
  user: User;
  reload: boolean;
  setReload: (reload: boolean) => void;
};
export const ResetPassword = ({ reload, setReload, user }: Props) => {
  const validationSchema = Yup.object()
    .shape({
      userName: Yup.string().required("User name is required"),
      password: Yup.string().required("Password is required"),
    })
    .required();

  const formOptions = {
    defaultValues: { userName: user.userName },
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.ResetPassword).update(user.id, values);
    reset();
    setReload(!reload);
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column mt-4"
    >
      <div className="form-group mb-4 d-flex flex-column align-items-start align-content-center">
        <label htmlFor="userName">User name</label>
        <input
          className="form-control"
          id="userName"
          type="userName"
          placeholder="User name"
          {...register("userName")}
        />
      </div>
      <div className="form-group mb-4 d-flex flex-column align-items-start align-content-center">
        <label htmlFor="password">Password</label>
        <input
          className="form-control"
          id="password"
          type="password"
          placeholder="Password"
          {...register("password")}
        />
      </div>
      <button
        type="submit"
        className="btn btn-success mt-3 d-flex align-items-center justify-content-center align-self-end"
      >
        {isSubmitting && (
          <span
            style={{ fontSize: "10px", textAlign: "center" }}
            className="spinner-border text-white mr-4"
          />
        )}
        <span>Update</span>
      </button>
    </form>
  );
};
