import { useEffect, useState } from "react";
import {
  ENDPOINTS,
  Operation,
  PaginatedResult,
  getUserInfo,
  httpService,
} from "@api";
import { Asset, AssetCategory, AssetStatus, RoleType } from "@models";
import {
  Action,
  ComplexHeader,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { CreateAssetCategory, EditAssetCategory } from "@components";

const headers: ComplexHeader[] = [
  {
    key: "id",
    title: "Asset ID",
  },
  {
    key: "name",
    title: "Name",
    formatter: (name: string) => name.capitalize(),
  },

  {
    key: "color",
    title: "Color",

    formatter: (color: string) => {
      return (
        <div className="badge">
          <div
            style={{
              backgroundColor: `${color ?? ""}`,
              color: "#fff",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
            }}
          ></div>
        </div>
      );
    },
  },
];

const AssetCategories = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);
  const [assetCategories, setAssetCategories] = useState<unknown[]>([]);

  const currentUser = getUserInfo();

  const [loading, setLoading] = useState(false);

  const toggle = withReactContent(Swal);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const res = await httpService(ENDPOINTS.AssetCategories).getAll();

      setAssetCategories(res.data ?? []);
      setLoading(false);
    })();
  }, [queryStrings, reload]);

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (category: AssetCategory) => {
        toggle.fire({
          showConfirmButton: false,
          showCloseButton: true,
          allowOutsideClick: false,
          width: "50%",
          html: (
            <EditAssetCategory
              category={category}
              reload={reload}
              setReload={setReload}
            />
          ),
        });
      },
      title: "Edit",
      color: "primary",
    },
    {
      key: "",
      actionType: "badge",
      hide: () => currentUser?.roleName !== RoleType.ADMIN,
      click: async (a: Asset) => {
        Swal.fire({
          title: "Are you sure you want to delete this Category?",
          // text: "By confirming deletion, this process is irrevocable, and the Billing will be permanently deleted.",
          icon: "warning",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, roll back!",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            await httpService(ENDPOINTS.AssetCategories).delete(a.id);
            setReload((prev) => (prev === undefined ? true : !prev));
          },
        });
      },
      title: "Delete Category",
      color: "danger",
    },
  ];

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const filters: Filterable<Asset>[] = [];

  const onCreateAssetCategory = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "40%",
      html: <CreateAssetCategory reload={reload} setReload={setReload} />,
    });
  };

  return (
    <div className="widget-content widget-content-area mt-4 w-100">
      <div className="d-flex justify-content-between mb-2">
        <div className="col-4">
          <div className="d-flex w-100">
            <button
              className="badge badge-dark"
              onClick={() => onCreateAssetCategory()}
            >
              <FaPlus />
              {"  "}
              Add Category
            </button>
          </div>
        </div>
        <div className="col-4">
          <TableVerticalConfigs
            filters={filters}
            // exportAsXSLS={onExport}
            // onPrint={{
            //   ref: "reportcontents",
            //   prePrint: onPrint,
            // }}
            ignorePageSizing={true}
          />
        </div>
      </div>
      <div className="col-12" id="reportcontents">
        <Table
          actions={actions}
          class="table table-sm table-striped table-hover mb-0"
          data={assetCategories ?? []}
          headers={headers}
          isFetchingPage={loading}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="d-flex justify-content-center mt-3"
        />
      </div>
    </div>
  );
};

export default AssetCategories;
