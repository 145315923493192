import { useEffect, useState } from "react";
import { ENDPOINTS, httpService, PagingOptions } from "../../api";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { Class } from "@models";

type Props = {
  onHandleChange: (value: { id: string; name: string }) => void;
};

const FilterByClass = ({ onHandleChange }: Props) => {
  const [classes, setClasses] = useState<Class[]>([]);

  useEffect(() => {
    (async function () {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Classes, options).getAll();

      setClasses(res.data.items ?? []);
    })();
  }, []);

  const onChange = (e: any) => {
    if (!e.target.value) return;

    onHandleChange({
      id: e.target.value,
      name: classes.find((c) => c.id === parseInt(e.target.value))?.name ?? "",
    });
  };

  return (
    <div className="form-group">
      <div className="form-group">
        <select
          id="classId"
          className="form-control form-control-sm"
          onChange={onChange}
        >
          <option value="all">All Classes</option>
          {classes.map((c) => {
            return (
              <option key={c.id} value={c.id}>
                {toUpperCaseFirstLetter(c.name)}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default FilterByClass;
