import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Class, ClassDay, Family, Teacher } from "@models";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ENDPOINTS, httpService, PagingOptions } from "../../api";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { BsPlusSquareFill, BsXSquareFill } from "react-icons/bs";
import { formatDate } from "../../helpers/fnHelpers";
import moment from "moment";

type Props = {
  data: { id: number; days: ClassDay[] };
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditClassPeriod = ({ reload, setReload, data }: Props) => {
  const validationSchema = Yup.object()
    .shape({
      days: Yup.array(
        Yup.object().shape({
          name: Yup.string().required("Name is required"),
          time: Yup.date().required("Time is required"),
        })
      ),
    })
    .required();

  console.log("dddd", data);

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      days: data.days.map((d: any) => ({
        name: d.name,
        time: moment(d.time).toDate(),
      })),
    },
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "days", // unique name for the Field Array
  });

  const handleOnSubmit = async (values: any) => {
    const days = values.days.map((day: any) => {
      return {
        name: day.name,
        time: moment(day.time).toString(),
      };
    });

    await httpService(ENDPOINTS.ClassPeriods).update(data.id, { days });
    setReload(!reload);
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        {fields.map((item: any, idx) => {
          return (
            <>
              <div className="col-sm-4 col-md-4 col-lg-4 py-3">
                <select
                  {...register(`days.${idx}.name`, {
                    required: "Gender is required",
                  })}
                  id="gender"
                  className="form-control"
                >
                  <option value="">Select Day</option>
                  {[
                    "Saturday",
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                  ].map((g) => (
                    <option
                      key={g}
                      value={g}
                      disabled={data.days.some((d) => d.name === g)}
                    >
                      {g}
                    </option>
                  ))}
                </select>{" "}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 py-3">
                <Controller
                  control={control}
                  name={`days.${idx}.time`}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      dateFormat="h:mm aa"
                      showTimeInput
                      showTimeSelectOnly
                      className="form-control form-control-sm"
                      placeholderText="Select a time"
                    />
                  )}
                />
              </div>{" "}
              <div className="col-sm-3 col-md-3 col-lg-3 d-flex align-items-center justify-content-center">
                <BsXSquareFill
                  style={{
                    cursor: "pointer",
                    color: "#e7515a",
                  }}
                  fontSize="30px"
                  onClick={() => remove(idx)}
                />
              </div>
            </>
          );
        })}
      </div>

      <div style={{ alignSelf: "flex-start" }} className="mt-2">
        <BsPlusSquareFill
          style={{ cursor: "pointer" }}
          fontSize="30px"
          onClick={() => {
            append({
              name: "",
              time: "",
            });
          }}
        />
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Update
        </button>
      </div>
    </form>
  );
};

export default EditClassPeriod;
