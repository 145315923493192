import withReactContent from "sweetalert2-react-content";
import { FaEdit, FaEllipsisH, FaEllipsisV, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import { ENDPOINTS, httpService } from "../../api";
import Paginate from "../../components/common/paginate";
import Delete from "../../components/forms/delete";
import { Family, Student, TableHeader } from "@models";
import EditStudent from "../../components/forms/editStudent";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import AddStudent from "../../components/forms/addStudent";

type Props = {
  families: Family[];
  page: number;
  perPage: number;
  totalPages: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  headers: TableHeader[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
};

const FamiliesTable = ({
  families,
  page,
  perPage,
  totalPages,
  headers,
  reload,
  setPage,
  setPerPage,
  setReload,
}: Props) => {
  const toggle = withReactContent(Swal);

  const handleDelete = async (id: number, ENDPOINT: ENDPOINTS) => {
    await httpService(ENDPOINT).delete(id);
    setReload(!reload);
  };

  const onDelete = (id: number, ENDPOINT: ENDPOINTS) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      html: <Delete id={id} onDelete={() => handleDelete(id, ENDPOINT)} />,
    });
  };

  const onEditStudent = (student: Student) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "50%",
      html: (
        <EditStudent student={student} reload={reload} setReload={setReload} />
      ),
    });
  };

  const onCreateStudent = (id: number) => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "50%",
      html: <AddStudent familyId={id} reload={reload} setReload={setReload} />,
    });
  };

  return (
    <div>
      {families.length > 0 &&
        families.map((f: Family) => {
          return (
            <div id="toggleAccordion" key={f.id}>
              <div className="card mt-2">
                <div className="px-2 py-3">
                  <div role="menu" className="d-flex justify-content-between">
                    <div
                      className="collapsed d-flex align-self-center"
                      data-toggle="collapse"
                      data-target={`#accordion${f.id}`}
                      aria-controls={`accordion${f.id}`}
                    >
                      <span className="text-decorate">
                        {toUpperCaseFirstLetter(`${f.name} (${f.code})`)}
                      </span>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="dropdown custom-dropdown">
                        <a
                          className="dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink1"
                          data-toggle="dropdown"
                          // aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <FaEllipsisV size={"20px"} color="#555" />
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink1"
                        >
                          <button
                            className="dropdown-item"
                            onClick={() => onCreateStudent(f.id)}
                          >
                            Add Student
                          </button>

                          <button
                            className="dropdown-item"
                            onClick={() => onDelete(f.id, ENDPOINTS.Families)}
                          >
                            Delete Family
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id={`accordion${f.id}`}
                  className="collapse bg-gray"
                  data-parent="#toggleAccordion"
                >
                  <div className="card-body p-0 m-10 bg-gray">
                    <table className="table">
                      <thead>
                        <tr>
                          {headers.map((h) => (
                            <th style={{ fontSize: "10px" }} key={h.id}>
                              {h.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {f.students.length > 0 &&
                          f.students.map((s) => {
                            return (
                              <tr key={s.id} className="">
                                <td className="">{s.code}</td>
                                <td className="">
                                  {toUpperCaseFirstLetter(
                                    `${s.firstName} ${s.secondName} ${s.lastName}`
                                  )}
                                </td>
                                <td className="">{f.parentName}</td>
                                <td className="">{f.email}</td>
                                <td className="">{f.whatsAppNumber}</td>
                                <td className="">{f.skypeAccount}</td>

                                <td className="">{f.country}</td>
                                <td className="">{f.city}</td>

                                <td className="">
                                  {s.class &&
                                    s.class.name !== undefined &&
                                    toUpperCaseFirstLetter(s.class.name ?? "")}
                                </td>

                                <td className="">{s.gender}</td>
                                <td>
                                  <div className="dropdown custom-dropdown">
                                    <a
                                      className="dropdown-toggle"
                                      href="#"
                                      role="button"
                                      id="dropdownMenuLink1"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <FaEllipsisH size={"20px"} color="#555" />
                                    </a>

                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuLink1"
                                    >
                                      <button
                                        className="dropdown-item"
                                        onClick={() => onEditStudent(s)}
                                      >
                                        Edit
                                      </button>

                                      <button
                                        className="dropdown-item"
                                        onClick={() =>
                                          onDelete(s.id, ENDPOINTS.Students)
                                        }
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      <div className="w-50">
        <Paginate
          canPreviousPage={page > 0 && true}
          canNextPage={totalPages - 1 !== page && true}
          currentPage={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          totalPage={totalPages}
        />
      </div>
    </div>
  );
};

export default FamiliesTable;
