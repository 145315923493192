import { useEffect, useState } from "react";
import {
  ENDPOINTS,
  Operation,
  PaginatedResult,
  getUserInfo,
  httpService,
} from "@api";
import { Asset, AssetCategory, AssetStatus, RoleType } from "@models";
import {
  Action,
  ComplexHeader,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { CreateAsset, EditAsset, EditStatus } from "@components";

const headers: ComplexHeader[] = [
  {
    key: "id",
    title: "Asset ID",
  },
  {
    key: "name",
    title: "Name",
    formatter: (name: string) => name.capitalize(),
  },
  {
    key: "code",
    title: "Code",
  },
  {
    key: "serialNumber",
    title: "Serial Number",
  },
  { key: "user?.firstName", title: "Responsible" },
  {
    key: "category",
    title: "Category",

    formatter: (category: AssetCategory) => {
      return (
        <span
          // className={`badge ${
          //   name === "Computers"
          //     ? "badge-success"
          //     : name === "Mouse"
          //     ? "badge-danger"
          //     : "badge-warning"
          // }`}
          className="badge"
          style={{
            backgroundColor: `${category.color ?? ""}`,
            color: "#fff",
          }}
        >
          {category.name ?? ""}
        </span>
      );
    },
  },
  {
    key: "status",
    title: "Status",
    formatter: (status: number) => (
      <span
        className={`badge ${status === 0 ? "badge-success" : "badge-danger"}`}
      >
        {status === 0 ? "Active" : "Depreciated"}
      </span>
    ),
  },
];

const Assets = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<string | null>(null);
  const [reload, setReload] = useState(false);
  const [assets, setAssets] = useState<PaginatedResult<Asset>>(
    new PaginatedResult()
  );

  const currentUser = getUserInfo();

  const [loading, setLoading] = useState(false);

  const toggle = withReactContent(Swal);

  useEffect(() => {
    (async () => {
      setLoading(true);

      status && queryStrings.set("status.eq", status);

      const res = await httpService(ENDPOINTS.Assets, queryStrings).getAll();

      const data = res.data as PaginatedResult<Asset>;
      setAssets(data ?? []);
      setLoading(false);
    })();
  }, [queryStrings, reload]);

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (asset: Asset) => {
        toggle.fire({
          showConfirmButton: false,
          showCloseButton: true,
          allowOutsideClick: false,
          width: "50%",
          html: (
            <EditAsset asset={asset} reload={reload} setReload={setReload} />
          ),
        });
      },
      title: "Edit",
      color: "primary",
    },
    {
      key: "",
      actionType: "badge",
      click: (asset: Asset) => {
        toggle.fire({
          showConfirmButton: false,
          showCloseButton: true,
          allowOutsideClick: false,
          width: "50%",
          html: (
            <EditStatus asset={asset} reload={reload} setReload={setReload} />
          ),
        });
      },
      title: "Change Status",
      color: "warning",
    },
    {
      key: "",
      actionType: "badge",
      hide: () => currentUser?.roleName !== RoleType.ADMIN,
      click: async (a: Asset) => {
        Swal.fire({
          title: "Are you sure you want to delete this Asset?",
          // text: "By confirming deletion, this process is irrevocable, and the Billing will be permanently deleted.",
          icon: "warning",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, roll back!",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            await httpService(ENDPOINTS.Assets).delete(a.id);
            setReload((prev) => (prev === undefined ? true : !prev));
          },
        });
      },
      title: "Delete Asset",
      color: "danger",
    },
  ];

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const filters: Filterable<Asset>[] = [
    {
      key: "name",
      title: "Name",
      format: "text",
      operation: Operation.like,
    },
    {
      key: "status",
      title: "Status",
      operation: Operation.like,
      format: "jsxElement",
      element: (
        <div className="mb-3">
          <select
            className="custom-select ml-3"
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value={undefined}></option>
            {Object.keys(AssetStatus)
              .filter((k) => !isNaN(+k))
              .map((key: any) => (
                <option key={key} value={AssetStatus[key]}>
                  {AssetStatus[key]}
                </option>
              ))}
          </select>
        </div>
      ),
    },
  ];

  const onCreateAsset = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "40%",
      html: <CreateAsset reload={reload} setReload={setReload} />,
    });
  };

  return (
    <div className="widget-content widget-content-area mt-4 w-100">
      <div className="d-flex justify-content-between mb-2">
        <div className="col-4">
          <div className="d-flex w-100">
            <button
              className="badge badge-success mr-4 p-2"
              onClick={() => onCreateAsset()}
            >
              <FaPlus />
              {"  "}
              Add Asset
            </button>
          </div>
        </div>
        <div className="col-4">
          <TableVerticalConfigs
            filters={filters}
            // exportAsXSLS={onExport}
            // onPrint={{
            //   ref: "reportcontents",
            //   prePrint: onPrint,
            // }}
            // ignorePageSizing={true}
          />
        </div>
      </div>
      <div className="col-12" id="reportcontents">
        <Table
          actions={actions}
          class="table table-sm table-striped table-hover mb-0"
          data={assets ?? []}
          headers={headers}
          isFetchingPage={loading}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="d-flex justify-content-center mt-3"
        />
      </div>
    </div>
  );
};

export default Assets;
