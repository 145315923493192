import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Class, Student } from "@models";
import { useForm } from "react-hook-form";
import { ENDPOINTS, httpService, PagingOptions } from "../../api";
import { useEffect, useState } from "react";

type Props = {
  student: Student;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditStudent = ({ student, reload, setReload }: Props) => {
  const [classes, setClasses] = useState<Class[]>([]);

  const validationSchema = Yup.object()
    .shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      secondName: Yup.string().required("Second name is required"),
      classId: Yup.number().required("Class id is required"),
    })
    .required();

  const formOptions = {
    defaultValues: {
      firstName: student.firstName,
      lastName: student.lastName,
      secondName: student.secondName,
      gender: student.gender,
      classId: student.classId,
    },
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<Student>(formOptions);

  const handleOnSubmit = async (values: Student) => {
    await httpService(ENDPOINTS.Students).update(student.id, values);
    setReload(!reload);
  };

  useEffect(() => {
    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Classes, options).getAll();

      setClasses(res.data.items ?? []);
    })();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="firstName"
            placeholder="First name"
            {...register(`firstName`)}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="lastName"
            placeholder="Last name"
            {...register(`lastName`)}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="secondName"
            placeholder="Second name"
            {...register(`secondName`)}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select {...register("gender")} id="gender" className="form-control">
            <option>Gender</option>
            {["Male", "Female"].map((g) => (
              <option key={g} value={g} selected={g === student.gender}>
                {g}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select
            {...register("classId")}
            id="classId"
            className="form-control"
          >
            <option>Select class</option>
            {classes.map((c) => (
              <option
                key={c.id}
                value={c.id}
                selected={c.id === student.classId}
              >
                {c.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default EditStudent;
