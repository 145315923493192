import { forwardRef } from "react";
import { Period } from "core/models/MonthAttendance";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";

function getMonthName(monthNumber: number) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Subtract 1 from monthNumber to get the correct index (1 -> 0, 2 -> 1, ..., 12 -> 11)
  return monthNames[monthNumber - 1];
}

const calculatePlannedTime = (noStudents: number) => {
  if (noStudents === 1) {
    return { min: 17, max: 25 };
  } else if (noStudents === 2) {
    return { min: 30, max: 38 };
  } else if (noStudents === 3) {
    return { min: 40, max: 45 };
  } else if (noStudents === 4) {
    return { min: 45, max: 55 };
  } else if (noStudents === 5) {
    return { min: 55, max: 65 };
  } else if (noStudents === 6) {
    return { min: 65, max: 75 };
  } else if (noStudents === 7) {
    return { min: 75, max: 85 };
  } else {
    return { min: 0, max: 0 };
  }
};

const isDelayed = (mins: number) => {
  if (mins < 0) {
    return Math.abs(mins);
  } else {
    return 0;
  }
};

const calculatePercentagePerformance = (props: any[]) => {
  if (!props || props.length === 0) return 0;

  const totalPeriods = props.reduce((acc: any, cur: any) => {
    return acc + cur.noperiods * 2;
  }, 0);
  const completedPeriods = props.reduce((acc: any, cur: any) => {
    return acc + cur.completedperiods;
  }, 0);
  const onTimePeriods = props.reduce((acc: any, cur: any) => {
    return acc + cur.ontimeperiods;
  }, 0);

  return `${(((completedPeriods + onTimePeriods) / totalPeriods) * 100).toFixed(
    2
  )}%`;
};

export const ClassAttendanceReportTable = forwardRef((props: any, ref: any) => {
  console.log("props", props.className);

  if (!props.classMonthlyAttendance || !props.selectedDate) return null;

  return (
    <div ref={ref} className="d-flex bg-white flex-column mt-4">
      <div className="d-flex justify-content-center my-5">
        <div className="mr-4">
          <h6 className="font-weight-bold text-align-center">
            Amiin Quran Online
          </h6>
          <hr />
        </div>
        <div className="align-self-center">
          <img src="/assets/img/logo_green.png" alt="logo" />
        </div>
        <div className="ml-3">
          <h6 className="font-weight-bold text-align-center">
            Class Attendance
          </h6>
          <hr />
        </div>
      </div>
      <div className="d-flex flex-column mx-4">
        <div className="d-flex justify-content-between">
          <div className="">
            <h6 className="font-weight-bold text-align-center">
              Class: {props.className ?? ""}
            </h6>
          </div>
          <div className="">
            <h6 className="font-weight-bold text-align-center">
              Month: {getMonthName(props.selectedDate.getMonth() + 1)}{" "}
              {props.selectedDate.getFullYear()}
            </h6>
          </div>
        </div>
      </div>
      {/* <div className="d-flex flex-column mx-4">
        <div className="d-flex justify-content-between">
          <div className="">
            <h6 className="font-weight-bold text-align-center">
              Family ID: {props.monthlyAttendance.family?.code ?? ""}
            </h6>
          </div>
          <div className="">
            <h6 className="font-weight-bold text-align-center">
              # of students: {props.monthlyAttendance.noStudents ?? 0}
            </h6>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column mx-4">
        <div className="d-flex justify-content-between">
          <div className="">
            <h6 className="font-weight-bold text-align-center">
              Planned time:{" "}
              {calculatePlannedTime(
                props.monthlyAttendance.noStudents ?? 0
              ).min.toString() +
                "-" +
                calculatePlannedTime(
                  props.monthlyAttendance.noStudents ?? 0
                ).max.toString()}{" "}
              mins
            </h6>
          </div>{" "}
          <div className="">
            <h6 className="font-weight-bold text-align-center">
              Skype Account:{" "}
              {props.monthlyAttendance.family?.skypeAccount ?? ""}
            </h6>
          </div>
        </div>
      </div> */}

      <table className="table bg-white px-5 mt-4">
        <thead className="bg-white">
          <tr>
            <th className="text-left " scope="col">
              Family Name
            </th>
            <th className="text-left " scope="col">
              Periods
            </th>
            <th className="text-left " scope="col">
              Completed Periods
            </th>
            <th className="text-left " scope="col">
              OnTime Periods
            </th>
          </tr>
        </thead>
        <tbody className="px-5">
          {props.classMonthlyAttendance.map((p: any) => {
            return (
              <tr key={p.familyid} className="px-5">
                <td className="text-left font-weight-bold">
                  {toUpperCaseFirstLetter(p.familyname)}
                </td>
                <td className="text-left font-weight-bold">{p.noperiods}</td>
                <td className="text-left font-weight-bold">
                  {p.completedperiods}
                </td>
                <td className="text-left font-weight-bold">
                  {p.ontimeperiods}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="d-flex flex-column mx-4">
        <div className="d-flex justify-content-between">
          <div className="">
            <h6 className="font-weight-bold text-align-center">
              Performance:{" "}
              {calculatePercentagePerformance(props.classMonthlyAttendance)}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
});
