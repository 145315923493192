import { getCurrentUser, getUserInfo } from "../api";
import { toUpperCaseFirstLetter } from "../helpers/fnHelpers";

const Profile = () => {
  const user = getUserInfo();
  return (
    <div className="widget-content widget-content-area mt-4 w-25">
      <div className="d-flex justify-content-between">
        <h3 className="">Profile</h3>
      </div>
      <div className="text-center user-info">
        <img src="/assets/img/90x90.jpg" alt="avatar" className="rounded" />
        <p className="mt-3">{toUpperCaseFirstLetter(user?.fullName ?? "")}</p>
      </div>
      <div className="user-info-list">
        <div className="">
          <ul className="contacts-block list-unstyled">
            <li className="contacts-block__item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-coffee mr-2"
              >
                <path d="M18 8h1a4 4 0 0 1 0 8h-1"></path>
                <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path>
                <line x1="6" y1="1" x2="6" y2="4"></line>
                <line x1="10" y1="1" x2="10" y2="4"></line>
                <line x1="14" y1="1" x2="14" y2="4"></line>
              </svg>
              {user?.roleName}
            </li>
            <li className="contacts-block__item mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-calendar mr-2"
              >
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="16" y1="2" x2="16" y2="6"></line>
                <line x1="8" y1="2" x2="8" y2="6"></line>
                <line x1="3" y1="10" x2="21" y2="10"></line>
              </svg>
              {user?.userName ?? ""}
            </li>
            <li className="contacts-block__item mt-3">
              <a href={`mailto:${user?.email ?? ""}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-mail mr-2"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
                {user?.email ?? ""}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Profile;
