import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { Class, Role } from "@models";
import { toUpperCaseFirstLetter } from "../../helpers/fnHelpers";
import { useEffect, useState } from "react";
import { ENDPOINTS, PagingOptions, httpService } from "../../api";

type Props = {
  reload: boolean;
  setReload: (reload: boolean) => void;
};

export const AddUser = ({ reload, setReload }: Props) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [classes, setClasses] = useState<Class[]>([]);

  const validationSchema = Yup.object()
    .shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      userName: Yup.string().required("User Name is required"),
      email: Yup.string().required("User Name is required"),
      password: Yup.string().required("Password is required"),
      roleId: Yup.number().required("Role id is required"),
      classId: Yup.number().required("Class id is required"),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  // Fetch Departments,Roles & Tenants
  useEffect(() => {
    (async function fetchRoles() {
      const res = await httpService(ENDPOINTS.Roles).getAll();
      setRoles(res.data ?? []);
    })();
    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Classes, options).getAll();
      setClasses(res.data.items ?? []);
    })();
  }, []);

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.Users).post(values);
    setReload(!reload);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="d-flex flex-column mt-4"
      >
        <div className="d-flex align-content-center">
          <div className="form-group w-100 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="First name"
              {...register("firstName", { required: true })}
            />
          </div>
          <div className="form-group ml-2 w-100 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Last name"
              {...register("lastName", { required: true })}
            />
          </div>
        </div>
        <div className="d-flex align-content-center">
          <div className="form-group w-100 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="firstName">User Name</label>
            <input
              type="text"
              className="form-control"
              id="userName"
              placeholder="User name"
              {...register("userName", { required: true })}
            />
          </div>
          <div className="form-group ml-2 w-100 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              className="form-control"
              id="email"
              placeholder="Email"
              {...register("email", { required: true })}
            />
          </div>
        </div>
        <div className="d-flex align-content-center">
          <div className="form-group w-100 mb-4 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="roleId">Role</label>
            <select
              {...register("roleId", {
                required: "select one option",
                valueAsNumber: true,
              })}
              id="roleId"
              className="form-control"
            >
              <option defaultValue="">Select role</option>
              {roles.map((r) => {
                return (
                  <option key={r.id} value={r.id}>
                    {toUpperCaseFirstLetter(r.name)}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group ml-2 w-100 d-flex flex-column align-items-start align-content-center">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              {...register("password", { required: true })}
            />
          </div>
        </div>

        <div className="d-flex align-content-start">
          <div className="form-group w-50 mb-4 d-flex flex-column align-items-start align-content-center">
            <select
              {...register("classId", {
                required: "Class id is required",
              })}
              id="classId"
              className="form-control"
            >
              <option>Select class</option>
              {classes.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-success mt-3 d-flex align-items-center justify-content-center align-self-end"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          <span>Save</span>
        </button>
      </form>
    </>
  );
};
