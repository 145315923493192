import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Class, Family, Student } from "@models";
import { useFieldArray, useForm } from "react-hook-form";
import { ENDPOINTS, httpService, PagingOptions } from "../../api";
import { useEffect, useState } from "react";
import { BsPlusSquareFill, BsXSquareFill } from "react-icons/bs";

type Props = {
  familyId: number;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddStudent = ({ familyId, reload, setReload }: Props) => {
  const [classes, setClasses] = useState<Class[]>([]);

  const validationSchema = Yup.object()
    .shape({
      students: Yup.array(
        Yup.object().shape({
          code: Yup.string().required("Code is required"),
          firstName: Yup.string().required("First name is required"),
          secondName: Yup.string().required("First name is required"),
          lastName: Yup.string().required("Last name is required"),
          classId: Yup.number().required("Class id is required"),
          gender: Yup.string().required("Gender is required"),
        })
      ),
    })
    .required();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const {
    control,
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.AddStudents).update(familyId, values.students);
    setReload(!reload);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "students", // unique name for the Field Array
  });

  useEffect(() => {
    (async () => {
      const options = new PagingOptions();
      options.size = 1000;

      const res = await httpService(ENDPOINTS.Classes, options).getAll();

      setClasses(res.data.items ?? []);
    })();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        {fields.map((item, idx) => {
          return (
            <div className="row w-100 mx-3 " key={idx}>
              <div className="col-sm-6 col-md-6 col-lg-6 py-3">
                <input
                  type="text"
                  className="form-control mr-2"
                  id="code"
                  placeholder="Student Id"
                  {...register(`students.${idx}.code`)}
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 py-3">
                <input
                  type="text"
                  className="form-control mr-2"
                  id="firstName"
                  placeholder="First name"
                  {...register(`students.${idx}.firstName`)}
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 py-3">
                <input
                  type="text"
                  className="form-control mr-2"
                  id="secondName"
                  placeholder="Second name"
                  {...register(`students.${idx}.secondName`)}
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 py-3">
                <input
                  type="text"
                  className="form-control mr-2"
                  id="lastName"
                  placeholder="Last name"
                  {...register(`students.${idx}.lastName`)}
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 py-3">
                <select
                  {...register(`students.${idx}.gender`, {
                    required: "Gender is required",
                  })}
                  id="gender"
                  className="form-control"
                >
                  <option value="">Select Gender</option>
                  {["Male", "Female"].map((g) => (
                    <option key={g} value={g}>
                      {g}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 py-3">
                <select
                  {...register(`students.${idx}.classId`, {
                    required: "Class id is required",
                  })}
                  id="classId"
                  className="form-control"
                >
                  <option>Select class</option>
                  {classes.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 py-3">
                <BsXSquareFill
                  style={{ cursor: "pointer", color: "#e7515a" }}
                  fontSize="30px"
                  onClick={() => remove(idx)}
                />
              </div>
            </div>
          );
        })}
        <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
          <span className="mr-2">Add Student</span>
          <BsPlusSquareFill
            style={{ cursor: "pointer" }}
            fontSize="30px"
            onClick={() => {
              append({});
            }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default AddStudent;
