import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ENDPOINTS, httpService, PagingOptions } from "../../api";
import { useEffect, useState } from "react";
import { Exam, Student, StudentExam, Subject } from "@models";

type Props = {
  studentExam: StudentExam;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};
const EditStudentExam = ({ studentExam, reload, setReload }: Props) => {
  const [exams, setExams] = useState<Exam[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [subjects, setSubjects] = useState<Subject[]>([]);

  const validationSchema = Yup.object()
    .shape({
      examId: Yup.number().required("exam id is required"),
      studentId: Yup.number().required("student id is required"),
      subjectId: Yup.number().required("subject id is required"),
      marks: Yup.number().required("Marks is required"),
    })
    .required();

  const formOptions = {
    defaultValues: {
      examId: studentExam.examId,
      studentId: studentExam.studentId,
      subjectId: studentExam.subjectId,
      marks: studentExam.marks,
    },
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.StudentExams).update(studentExam.id, values);
    setReload(!reload);
  };

  useEffect(() => {
    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Exams, options).getAll();

      setExams(res.data.items ?? []);
    })();

    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Students, options).getAll();

      setStudents(res.data.items ?? []);
    })();

    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Subjects, options).getAll();

      setSubjects(res.data.items ?? []);
    })();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select {...register("examId")} id="examId" className="form-control">
            <option>Select exam</option>
            {exams.map((e) => (
              <option
                key={e.id}
                value={e.id}
                selected={e.id === studentExam.examId}
              >
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select
            {...register("studentId")}
            id="studentId"
            className="form-control"
          >
            <option>Select student</option>
            {students.map((s) => (
              <option
                key={s.id}
                value={s.id}
                selected={s.id === studentExam.studentId}
              >
                {`${s.firstName} ${s.lastName}`}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select
            {...register("subjectId")}
            id="subjectId"
            className="form-control"
          >
            <option>Select subject</option>
            {subjects.map((s) => (
              <option
                key={s.id}
                value={s.id}
                selected={s.id === studentExam.subjectId}
              >
                {s.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="number"
            className="form-control mr-2"
            id="marks"
            placeholder="marks"
            {...register(`marks`)}
            min={1}
            max={100}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default EditStudentExam;
