import NavBar from "./navbar";
import NewNavBar from "./newNavBar";
import NewSideBar from "./newSideBar";
import SideBar from "./sidebar";
import SubNavBar from "./subNavBar";

type Props = {
  children: JSX.Element;
};

const Index = ({ children }: Props) => {
  return (
    <div>
      <NewNavBar />
      <SubNavBar />
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>
        <NewSideBar />
        <div id="content" className="main-content">
          <div className="layout-px-spacing">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Index;
