import { Asset, AssetStatus } from "@models";
import { useForm } from "react-hook-form";
import { ENDPOINTS, httpService } from "../../api";

type Props = {
  asset: Asset;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditStatus = ({ asset, reload, setReload }: Props) => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.AssetStatus).update(asset.id, {
      status: +values.status,
    });
    setReload(!reload);
  };
  console.log("first", asset.status);
  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select {...register("status")} id="status" className="form-control">
            {Object.keys(AssetStatus)
              .filter((k) => !isNaN(+k))
              .map((key: any) => (
                <option
                  key={key}
                  value={key}
                  selected={Number(key) === asset.status}
                >
                  {AssetStatus[key]}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default EditStatus;
