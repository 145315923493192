import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { ENDPOINTS, httpService } from "../../api";
import { Exam } from "@models";

type Props = {
  exam: Exam;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};
const EditExam = ({ exam, reload, setReload }: Props) => {
  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("Exam name is required"),
      examDate: Yup.date().required("Exam date is required"),
    })
    .required();

  const formOptions = {
    defaultValues: { name: exam.name },
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.Exams).update(exam.id, values);
    setReload(!reload);
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-12 col-md-12 col-lg-12 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="name"
            placeholder="Name"
            {...register(`name`)}
          />
        </div>

        <div className="mx-2 w-100 px-2">
          <Controller
            control={control}
            name="examDate"
            render={({ field }) => (
              <DatePicker
                className="form-control"
                placeholderText="Select meeting date"
                onChange={field.onChange}
                selected={field.value}
                // showTimeSelect
                // timeFormat="HH:mm"
                // dateFormat="MM/dd/yyyy HH:mm"
              />
            )}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default EditExam;
