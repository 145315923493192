import { ENDPOINTS, httpService } from "@api";
import { Family, User } from "@models";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Select from "react-select";

interface Props {
  setSelectedFamily: Dispatch<SetStateAction<number | null>>;
}

export type SelectOption = {
  id: number;
  value: string;
  label: string;
};

const SearchFamily = ({ setSelectedFamily }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [families, setFamilyName] = useState<Family[]>([]);

  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    null
  );

  const fetchUsers = async (searchTerm: string) => {
    const options = new URLSearchParams();
    const checkValue = +searchTerm;

    if (isNaN(checkValue)) {
      options.append("name", searchTerm);
      const res = await httpService(
        ENDPOINTS.FamilyQuickSearch,
        options
      ).getAll();

      setFamilyName(res.data ?? []);
    }
  };

  useEffect(() => {
    // const { cancel, token } = axios.CancelToken.source();

    const timeOutId = setTimeout(() => {
      if (searchTerm !== "") {
        fetchUsers(searchTerm);
      }
    }, 800);

    return () => clearTimeout(timeOutId);
  }, [searchTerm]);

  const handleChange = (selectedOption: any) => {
    setSelectedFamily(selectedOption.id);
    setSelectedOption(selectedOption);
  };

  const handleInputChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  return (
    <div className="form-row mt-4 row">
      <div className="form-group col-12">
        <Select
          value={selectedOption}
          onChange={handleChange}
          onInputChange={handleInputChange}
          options={families.map((f) => {
            return {
              id: f.id,
              value: f.name,
              label: f.name,
            };
          })}
          isSearchable={true}
          placeholder="Search families..."
        />
      </div>
    </div>
  );
};

export default SearchFamily;
