import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Asset, AssetCategory, User } from "@models";
import { useForm } from "react-hook-form";
import { ENDPOINTS, httpService, PagingOptions } from "../../api";
import { useEffect, useState } from "react";

type Props = {
  asset: Asset;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditAsset = ({ asset, reload, setReload }: Props) => {
  const [categories, setCategories] = useState<AssetCategory[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("Name is required"),
      code: Yup.string().required("Code is required"),
      serialNumber: Yup.string().required("Serial Number is required"),
      categoryId: Yup.number().required("CategoryId is required"),
      userId: Yup.number().required("UserId is required"),
    })
    .required();

  const formOptions = {
    defaultValues: {
      name: asset.name,
      code: asset.code,
      serialNumber: asset.serialNumber,
      userId: asset.userId,
      categoryId: asset.categoryId,
    },
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm(formOptions);

  const handleOnSubmit = async (values: any) => {
    await httpService(ENDPOINTS.Assets).update(asset.id, values);
    setReload(!reload);
  };

  useEffect(() => {
    (async () => {
      const res = await httpService(ENDPOINTS.AssetCategories).getAll();

      setCategories(res.data ?? []);
    })();

    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Users, options).getAll();

      setUsers(res.data ?? []);
    })();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="name"
            placeholder="Name"
            {...register(`name`)}
          />
        </div>

        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="code"
            placeholder="code"
            {...register(`code`)}
          />
        </div>
      </div>

      <div className="row w-100">
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="serialNumber"
            placeholder="Serial Number"
            {...register(`serialNumber`)}
          />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select
            {...register("categoryId")}
            id="categoryId"
            className="form-control"
          >
            <option value="Choose">Select Category</option>
            {categories.map((c) => (
              <option
                key={c.id}
                value={c.id}
                selected={c.id === asset.categoryId}
              >
                {c.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="row w-100">
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select {...register("userId")} id="userId" className="form-control">
            <option value="Choose">Select Responsible User</option>
            {users.map((u) => (
              <option key={u.id} value={u.id} selected={u.id === asset.userId}>
                {`${u.firstName} ${u.lastName}`}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default EditAsset;
