import { useEffect, useMemo, useRef, useState } from "react";
import AttendanceTable from "./attendanceTable";
import DatePicker from "react-datepicker";
import { FaPlus } from "react-icons/fa";
import {
  ENDPOINTS,
  PaginatedResult,
  PagingOptions,
  getCurrentUser,
  getUserInfo,
  httpService,
} from "../../api";
import moment from "moment";
import Loader from "../../components/common/loader";
import AddAttendance from "../../components/forms/addAttendance";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import useFetch from "../../hooks/useFetch";
import { Attendance, Class, RoleType } from "@models";

const Index = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [classId, setClassId] = useState<number | undefined>();
  const [classLoad, setClassLoad] = useState(false);
  const [reload, setReload] = useState(false);
  const toggle = withReactContent(Swal);
  const user = getUserInfo();

  const getUrlSearchParam = useMemo(() => {
    const urlSearchParam = new URLSearchParams();
    urlSearchParam.append("size", "1000");

    return urlSearchParam;
  }, []);

  const getOptions = useMemo(() => {
    const options = new PagingOptions();
    options.filter<Attendance>(
      (a) =>
        a.eq("month", moment(selectedDate).month() + 1) &&
        a.eq("year", moment(selectedDate).year())
    );

    options.size = 1000;

    user?.roleName === RoleType.INSTRUCTOR &&
      options.filter<Attendance>((a) => a.eq("classId", user.classId));

    return options;
  }, [selectedDate]);

  const { data, loading } = useFetch<Attendance>(
    ENDPOINTS.Attendances,
    getOptions,
    reload
  );

  const { data: classes } = useFetch<Class>(
    ENDPOINTS.Classes,
    getUrlSearchParam,
    reload
  );

  useEffect(() => {
    let clearTimeOutId: string | number | NodeJS.Timeout | undefined;

    if (classId) {
      setClassLoad(true);
      clearTimeOutId = setTimeout(() => {
        setClassLoad(false);
      }, 500);
    } else {
      setClassLoad(false);
    }

    return () => clearTimeout(clearTimeOutId);
  }, [classId]);

  const onCreateAttendance = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "50%",
      html: (
        <AddAttendance
          reload={reload}
          setReload={setReload}
          attendances={data}
          month={moment(selectedDate).month() + 1}
          year={moment(selectedDate).year()}
        />
      ),
    });
  };

  return (
    <div className="widget-content widget-content-area mt-4 w-100">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <DatePicker
            className="form-control"
            placeholderText="Select month"
            onChange={(e) => {
              setSelectedDate(e);
              setReload(!reload);
              setClassId(undefined);
            }}
            selected={selectedDate}
            dateFormat="MM"
            showMonthYearPicker
          />
          {user?.roleName === RoleType.ADMIN && (
            <select
              className="form-control ml-2"
              onChange={(e) => {
                setClassId(+e.target.value);
              }}
            >
              <option value={-1}>FilterBy Class</option>
              {classes?.map((c) => (
                <option key={c.id} value={c.id}>
                  {`${c.name ?? ""} (${c.teacher?.firstName ?? ""})`}
                </option>
              ))}
            </select>
          )}
        </div>
        <div>
          <button
            className="btn btn-sm btn-success mr-3"
            onClick={() => onCreateAttendance()}
          >
            <FaPlus className="mr-3" />
            Add Attendance
          </button>
          <button className="btn btn-sm btn-success">Export</button>
        </div>
      </div>
      {data.length > 0 && !loading && !classLoad ? (
        <AttendanceTable
          selectedDate={selectedDate}
          attendances={data.filter((a) =>
            classId && classId !== -1 ? a.classId === classId : a
          )}
          reload={reload}
          setReload={setReload}
        />
      ) : loading || classLoad ? (
        <Loader />
      ) : (
        <div className="mt-4">NO DATA!</div>
      )}
    </div>
  );
};

export default Index;
