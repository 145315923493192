type Props = {
  setPage: (param: number) => void;
  setPerPage: (param: number) => void;
  currentPage: number;
  totalPage: number;
  perPage: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
};

const Paginate = ({
  setPage,
  setPerPage,
  currentPage,
  totalPage,
  perPage,
  canPreviousPage,
  canNextPage,
}: Props) => {
  return (
    <div className="mb-2 mt-3 w-100">
      <div className="d-flex align-items-center justify-content-start">
        <button
          className="btn btn-outline-primary mr-2 btn-sm"
          onClick={() => setPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>
        <button
          className="btn btn-outline-primary mr-2 btn-sm"
          onClick={() => setPage(currentPage - 1)}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        <button
          className="btn btn-outline-primary mr-2 btn-sm"
          onClick={() => setPage(currentPage + 1)}
          disabled={!canNextPage}
        >
          {">"}
        </button>
        <button
          className="btn btn-outline-primary mr-2 btn-sm"
          onClick={() => setPage(totalPage - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>
        <span className="mr-2">Page: </span>
        <div className="input-group input-group-sm w-5">
          <input
            className="form-control"
            type="number"
            value={currentPage}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) : 0;
              setPage(page);
            }}
          />
        </div>
        <span className="mx-2 w-10">
          of <span>{totalPage}</span>
        </span>
        <div className="input-group input-group-sm w-15">
          <select
            className="form-control ml-2"
            value={perPage}
            onChange={(e) => {
              setPerPage(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((perPage) => (
              <option key={perPage} value={perPage}>
                Show {perPage}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Paginate;
