import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ENDPOINTS, httpService } from "../api";
import { Family, Student } from "@models";
import moment from "moment";
import { Days } from "@models";

const FILETYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const FILE_EXTENSION = ".xlsx";

export function ConstructSearchParams(
  page: number,
  perPage: number
): URLSearchParams {
  const params = new URLSearchParams();
  params.append("page", page.toString());
  params.append("size", perPage.toString());
  return params;
}
export function toUpperCaseFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
export function displayString(text: string, count: number) {
  return text.slice(0, count) + (text.length > count ? "..." : "");
}

function createDefaultObj(keys: string[], objData: any) {
  return keys.reduce((obj, key) => {
    if (key === "familyId") {
      obj["Family ID"] = objData["family"]["code"];
    } else if (key === "code") {
      obj["Student ID"] = objData[key];
    } else if (key === "firstName") {
      obj["FirstName"] = objData[key];
    } else if (key === "secondName") {
      obj["SecondName"] = objData[key];
    } else if (key === "lastName") {
      obj["LastName"] = objData[key];
    } else if (key === "gender") {
      obj["Gender"] = objData[key];
    } else if (key === "class") {
      obj["Class"] = objData[key]["name"];
    } else if (key === "family") {
      obj["Family"] = objData[key]["name"];
    } else {
      obj[key] = objData[key];
    }

    return obj;
  }, {} as Record<string, any>);
}

export async function exportDataCSV(keys: string[], data: any[]) {
  const formattedData = data.map((d) => {
    return createDefaultObj(keys, d);
  });

  const ws = XLSX.utils.json_to_sheet(formattedData);

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const result = new Blob([excelBuffer], { type: FILETYPE });

  FileSaver.saveAs(result, "students" + FILE_EXTENSION);
}

export async function exportToCSV(keys: string[], data: any[]) {
  const formattedData = data.map((d) => {
    return keys.reduce((obj, key) => {
      if (key === "students") {
        obj[key] = d[key].length;
      } else {
        obj[key] = d[key];
      }

      return obj;
    }, {} as Record<string, any>);
  });

  console.log("data", formattedData);

  const ws = XLSX.utils.json_to_sheet(formattedData);

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const result = new Blob([excelBuffer], { type: FILETYPE });

  FileSaver.saveAs(result, "families" + FILE_EXTENSION);
}

export function getDaysFromMonth(year: number, month: number): Days[] {
  let days = Array.from(
    {
      length: moment()
        .set("month", month - 1)
        .daysInMonth(),
    },
    (_, i) => i + 1
  );

  let formattedDays = days.map((d) => {
    return { day: d, minutes: 0, remarks: "" };
  });

  if (moment([year]).isLeapYear() && month === 2) {
    formattedDays.push({ day: 29, minutes: 0, remarks: "" });
  }

  return formattedDays;
}

export function formatDate(date: Date) {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
}
