import React, { useEffect, useState } from "react";
import ClassChart from "../components/charts/classChart";
import { ENDPOINTS, httpService } from "../api";
import { ClassStats, FamilyStats } from "@models";
import { toUpperCaseFirstLetter } from "../helpers/fnHelpers";
import Paginate from "../components/common/paginate";

const Home = () => {
  const [classStats, setClassStats] = useState<ClassStats[]>([]);
  const [familyStats, setFamilyStats] = useState<FamilyStats[]>([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    (async () => {
      const res = await httpService(ENDPOINTS.ClassStats).getAll();

      setClassStats(res.data ?? []);
    })();

    (async () => {
      const res = await httpService(ENDPOINTS.FamilyStats).getAll();
      setFamilyStats(res.data ?? []);
      setTotalPages(res.data && Math.floor(res.data.length / perPage));
    })();
  }, []);

  function paginate(array: ClassStats[], page: number, size: number) {
    return array.slice(
      page === 0 ? page : page * size,
      page === 0 ? size : (page + 1) * size
    );
  }

  return (
    <div className="row layout-top-spacing">
      <ClassChart classStats={classStats} />
      <div className="col-xl-8 col-lg-12  col-md-12 col-sm-12 layout-spacing h-50">
        <div className="widget-content widget-content-area">
          <div>
            <div className="widget-account-invoice-one">
              <div className="widget-heading">
                <h5 className="">School Info</h5>
              </div>

              <div className="widget-content">
                <div className="invoice-box">
                  <div className="acc-total-info">
                    <h5># of students</h5>
                    <p className="acc-amount">
                      {classStats.reduce((a: any, b: any) => {
                        return a + b.noStudents;
                      }, 0)}
                    </p>
                  </div>

                  <div className="inv-detail">
                    {familyStats.length > 0 &&
                      paginate(familyStats, page, perPage).map((f) => {
                        return (
                          <div className="info-detail-1">
                            <p>{toUpperCaseFirstLetter(f.name)}</p>
                            <p>{f.noStudents}</p>
                          </div>
                        );
                      })}
                  </div>
                  <div className="d-flex justify-content-end w-100">
                    <div className="w-50">
                      <Paginate
                        canPreviousPage={page > 0 && true}
                        canNextPage={totalPages !== page && true}
                        currentPage={page}
                        perPage={perPage}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        totalPage={totalPages}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Home;
