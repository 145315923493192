import { useFieldArray, useForm } from "react-hook-form";
import { ENDPOINTS, httpService } from "../../api";
import { Attendance, Days } from "@models";
import { BsPlusSquareFill, BsXSquareFill } from "react-icons/bs";

type Props = {
  attendance: Attendance;
  reload: boolean;
  setReload: (reload: boolean) => void;
};

const AddRemark = ({ attendance, reload, setReload }: Props) => {
  console.log("remarks", attendance.remarks);
  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting },
  } = useForm({ defaultValues: { remarks: attendance.remarks } });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "remarks", // unique name for the Field Array
  });

  const handleOnSubmit = async (values: any) => {
    if (attendance) {
      await httpService(ENDPOINTS.Attendances).update(attendance.id, {
        days: attendance.days,
        remarks: values.remarks,
      });

      setReload(!reload);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        {fields.map((item, idx) => {
          return (
            <div className="row w-100 mx-3">
              <div className="col-sm-2 col-md-2 col-lg-2 py-2 mt-3">
                <select
                  {...register(`remarks.${idx}.day`, {
                    required: "Day is required",
                  })}
                  id="day"
                  className="form-control"
                >
                  {attendance &&
                    attendance.days.map((d: Days) => (
                      <option key={d.day} value={d.day}>
                        {d.day}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 py-2">
                <textarea
                  className="form-control mr-2"
                  id="comment"
                  placeholder="Comment"
                  {...register(`remarks.${idx}.comment`, {
                    required: "Comment is required",
                  })}
                />
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 py-3">
                <BsXSquareFill
                  style={{ cursor: "pointer", color: "#e7515a" }}
                  fontSize="30px"
                  onClick={() => remove(idx)}
                />
              </div>
            </div>
          );
        })}
      </div>

      <div style={{ alignSelf: "flex-start" }} className="mt-2">
        <span className="mr-2">Add Comment</span>
        <BsPlusSquareFill
          style={{ cursor: "pointer" }}
          fontSize="30px"
          onClick={() => {
            append({ day: 0, comment: "" });
          }}
        />
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default AddRemark;
