import { Operation, PagingOptions } from "@api";
import { Filterable, FilterableType } from "@shared";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TableDefaults, ExportData, PrintComponent } from ".";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  RiFileExcel2Fill,
  RiFilterLine,
  RiFilterOffFill,
  RiPrinterFill,
} from "react-icons/ri";
import * as XLSX from "xlsx";

interface Props<T extends unknown> {
  callback?: (options: PagingOptions) => void;
  filters?: Filterable<T>[];
  exportAsXSLS?: ExportData;
  onPrint?: PrintComponent;
  className?: string;
  color?: string;
  ignorePagingOptionsFilterStyle?: boolean;
  ignoreSorting?: boolean;
  ignorePageSizing?: boolean;
}

export const TableVerticalConfigs = <T extends unknown>({
  callback,
  filters,
  exportAsXSLS,
  onPrint,
  className,
  color,
  ignorePagingOptionsFilterStyle,
  ignoreSorting = false,
  ignorePageSizing = false,
}: Props<T>) => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [queryStrings] = useSearchParams();

  const [queryStringsExist, setQueryStringsExist] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    // var queryString = queryStrings.get("size");
    if (queryStrings.toString()) {
      setQueryStringsExist(true);
    } else {
      setQueryStringsExist(false);
    }
  }, [queryStrings]);

  const showFiltersOnClickHandler = () => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 1200,
      html: (
        <ShowVerticalFilters
          callback={applyFilter}
          fields={filters!}
          ignoreSorting={ignoreSorting}
          ignorePageSizing={ignorePageSizing}
        />
      ),
    });
  };

  const applyFilter = (params: {}[]) => {
    if (ignorePagingOptionsFilterStyle) {
      const options = new URLSearchParams();
      params.forEach((param: any) => {
        param.type === "filter" && options.set(param.field, param.value);
      });
      navigate({ search: `?${options.toString()}` });
    } else {
      const options = new PagingOptions(TableDefaults.page, TableDefaults.size);

      params.forEach((param: any) => {
        // param.type === 'select' && Object.assign(options, options.select(s => s.select<T>(...param.fields)));
        param.type === "filter" &&
          Object.assign(
            options,
            options.filter<T>((f) =>
              f[param.operation as Operation](param.field, param.value)
            )
          );
        param.type === "sort" &&
          Object.assign(options, options.sort<T>(param.field, param.ascending));
        param.type === "page" &&
          Object.assign(options, (options.page = param.value));
        param.type === "size" &&
          Object.assign(options, (options.size = param.value));
      });

      navigate({ search: `?${options.format()}` });
      callback?.(options);
    }
  };

  const onResetClickHandler = () => {
    navigate({ search: `` });
  };

  const handleOnExport = async () => {
    var readyData = await exportAsXSLS?.data();
    if (!readyData || readyData.length === 0) return alert("No data to export");

    setIsExporting(true);

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(readyData ?? []);
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      `${exportAsXSLS?.sheetName ?? "Sheet 01"}`
    );
    XLSX.writeFile(wb, `${exportAsXSLS?.fileName}.xlsx`);

    setTimeout(() => {
      setIsExporting(false);
    }, 10_000);
  };

  const onPrintTbl = () => {
    var originalContents = document.body.innerHTML;

    // Remove the bottom pagination if exists
    var bottomPagination = document.getElementById("bottomPagination");
    bottomPagination && bottomPagination?.classList.add("d-none");

    var element = document.getElementById(onPrint?.ref ?? "") as HTMLElement;

    onPrint?.prePrint?.(element);

    let printContents = element.innerHTML;
    document.body.innerHTML = printContents;
    setTimeout(function () {
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    }, 500);
  };

  return (
    <>
      {(filters ?? []).length > 0 && (
        <button
          className={`btn btn-sm btn-outline-dark rounded-circle m-1 float-right ${className}`}
          onClick={showFiltersOnClickHandler}
        >
          <RiFilterLine color={`${color || ""}`} />
        </button>
      )}
      {queryStringsExist && (filters ?? []).length > 0 && (
        <button
          className="btn btn-sm btn-outline-danger rounded-circle m-1 float-right"
          onClick={onResetClickHandler}
        >
          <RiFilterOffFill />
        </button>
      )}
      {Object.keys(exportAsXSLS ?? {}).length > 0 && (
        <button
          className={`btn btn-sm btn-outline-${
            isExporting ? "dark" : "success"
          } rounded-circle m-1 float-right`}
          onClick={handleOnExport}
          disabled={isExporting}
        >
          <RiFileExcel2Fill />
        </button>
      )}
      {onPrint && (
        <button
          className="btn btn-sm btn-outline-warning rounded-circle m-1 float-right"
          onClick={onPrintTbl}
        >
          <RiPrinterFill />
        </button>
      )}
    </>
  );
};

interface miniProps<T extends unknown> {
  callback: (filters: {}[]) => void;
  fields: Filterable<T>[];
  ignoreSorting: boolean;
  ignorePageSizing: boolean;
}

export const ShowVerticalFilters = <T extends unknown>({
  callback,
  fields,
  ignoreSorting,
  ignorePageSizing,
}: miniProps<T>) => {
  const displayOperation = (o: Operation): string => {
    switch (o) {
      case Operation.eq:
        return "Equal";
      case Operation.gte:
        return "Greater than or Equal";
      case Operation.lte:
        return "Less than or Equal";
      case Operation.gt:
        return "Greater than";
      case Operation.lt:
        return "Less than";

      default:
        return "";
    }
  };

  const renderInputType = (field: string) => {
    const inputType = fields.filter((f) => f.key === field)[0]
      .format as FilterableType;

    const selectBoxData = fields?.filter((f) => f.key === field)[0]?.data;
    const element = fields?.filter((f) => f.key === field)[0]?.element;

    if (
      inputType === "text" ||
      inputType === "date" ||
      inputType === "month" ||
      inputType === "number"
    ) {
      return (
        <div className="col-5">
          <div className="form-group">
            <input
              type={inputType}
              name={`${field}filterValue`}
              className="form-control"
              // ref={register()}
            />
          </div>
        </div>
      );
    }
    if (inputType === "select") {
      return (
        <div className="col-5">
          <div className="form-group">
            <select
              name={`${field}filterValue`}
              className="custom-select custom-select-sm"
              // ref={register()}
            >
              <option value=""> </option>
              {selectBoxData ? (
                selectBoxData.map((f, i) => {
                  return (
                    <option key={i} value={f.id}>
                      {f.name}
                    </option>
                  );
                })
              ) : (
                <option value="">no Data</option>
              )}
            </select>
          </div>
        </div>
      );
    }
    if (inputType === "dateRange") {
      return (
        <>
          <div className="col">
            <div className="form-group">
              <input
                type="date"
                name={`${field}From`}
                className="form-control"
                // ref={register()}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <input
                type="date"
                name={`${field}To`}
                className="form-control"
                // ref={register()}
              />
            </div>
          </div>
        </>
      );
    }
    if (inputType === "numberRange") {
      return (
        <>
          <div className="col">
            <div className="form-group">
              <input
                type="number"
                name={`${field}StartNumberRange`}
                className="form-control"
                // ref={register()}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <input
                type="number"
                name={`${field}EndNumberRange`}
                className="form-control"
                // ref={register()}
              />
            </div>
          </div>
        </>
      );
    }
    if (inputType === "jsxElement") {
      return element;
    }
  };

  const handleButtonSubmit = (e: any) => {
    e.preventDefault();

    const data = e.target;
    const filters: {}[] = [];

    fields.forEach((f) => {
      const filterOperation = data[`${f.key! as string}filterOperation`]?.value;
      const filterValue = data[`${f.key! as string}filterValue`]?.value;

      if (filterValue) {
        filters.push({
          type: "filter",
          field: f.key,
          operation: filterOperation ?? f?.operation ?? Operation.eq,
          value:
            typeof f?.inputFormat === "undefined"
              ? filterValue
              : f.inputFormat(filterValue),
        });
      }

      // for date ranges
      let from = data[`${f.key! as string}From`]?.value;
      let to = data[`${f.key! as string}To`]?.value;

      if (from && to) {
        // from = new Date(new Date(from).setHours(0, 0, 0, 1)).toISOString();
        // to = new Date(new Date(to).setHours(23, 59, 59, 999)).toISOString();
        from = from + "T00:00:00.001Z";
        to = to + "T23:59:59.999Z";
        filters.push({
          type: "filter",
          field: f.key,
          operation: Operation.gte,
          value: from,
        });
        filters.push({
          type: "filter",
          field: f.key,
          operation: Operation.lte,
          value: to,
        });
      }

      // for NumberRanges
      let fromNumberRange = data[`${f.key! as string}StartNumberRange`]?.value;
      let toNumberRange = data[`${f.key! as string}EndNumberRange`]?.value;

      if (fromNumberRange && toNumberRange) {
        filters.push({
          type: "filter",
          field: f.key,
          operation: Operation.gte,
          value: +fromNumberRange,
        });
        filters.push({
          type: "filter",
          field: f.key,
          operation: Operation.lte,
          value: +toNumberRange,
        });
      }
    });

    data?.SortField?.value &&
      filters.push({
        type: "sort",
        field: data.SortField?.value,
        ascending: data?.sortDirection?.value ?? true,
      });
    data?.pageSize?.value &&
      filters.push({ type: "size", value: data.pageSize?.value });

    callback(filters);

    e.target.reset();
    Swal.close();
  };

  return (
    <>
      <Helmet>
        <title>Filtering</title>
        <link href="/assets/customInput.css" rel="stylesheet" type="text/css" />
      </Helmet>

      <h5>Table filtering dashboard</h5>
      <hr />
      <form
        // onSubmit={handleSubmit(onSubmit)}
        onSubmit={handleButtonSubmit}
      >
        <div className="row">
          <div className="col-2">
            <h5 className="font-weight-bold mt-3">Filter</h5>
          </div>
          <div className="col-10">
            {fields.map((f, i) => {
              return (
                <div className="row">
                  <div className="col-2  mt-3 text-left">
                    <h6>{f.title.capitalize()}</h6>
                  </div>
                  {f?.showOperations && (
                    <div className="col">
                      <select
                        className="custom-select"
                        name={`${f.key! as string}filterOperation`}
                        // ref={register()}
                      >
                        <option value=""> </option>
                        {Object.keys(Operation).map((o, i) => {
                          return (
                            <option key={i} value={o}>
                              {displayOperation(
                                Operation[o as unknown as Operation]
                              )}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  {renderInputType(f.key! as string)}
                </div>
              );
            })}
          </div>
        </div>

        <div className="row">
          <div className="col-4"></div>
          <div className="col">
            <hr style={{ borderTop: "1px solid #D6D8D9" }} />
          </div>
          <div className="col-1"></div>
        </div>

        {!ignoreSorting && (
          <>
            <div className="row">
              <div className="col-2">
                <h5 className="font-weight-bold mt-3">Sort</h5>
              </div>
              <div className="col-10">
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col">
                    <select
                      className="custom-select custom-select-sm"
                      name="SortField"
                      // ref={register()}
                    >
                      <option value=""> </option>
                      {fields.map((f, i) => {
                        return (
                          <option key={i} value={f.key! as string}>
                            {f.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col">
                    <select
                      className="custom-select custom-select-sm"
                      name="sortDirection"
                      // ref={register()}
                    >
                      <option value=""> </option>
                      <option value="true">Ascending</option>
                      <option value="false">Descending</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4"></div>
              <div className="col">
                <hr style={{ borderTop: "1px solid #D6D8D9" }} />
              </div>
              <div className="col-1"></div>
            </div>
          </>
        )}

        {!ignorePageSizing ? (
          <div className="row mt-3 pt-1">
            <>
              <div className="col-2">
                <h5 className="font-weight-bold mt-3">Page size</h5>
              </div>

              <div className="col-4 offset-2">
                <div className="form-group row  ">
                  <div className="col-sm-10">
                    <input
                      type="number"
                      placeholder="page size"
                      name="pageSize"
                      className="form-control"
                      // ref={register()}
                    />
                  </div>
                </div>
              </div>

              <div className="col-2 offset-2 mt-1">
                <input
                  type="submit"
                  id="submit"
                  className="btn btn-dark float-right"
                />
              </div>
            </>
          </div>
        ) : (
          <div className="d-flex justify-content-end">
            <div className="col-2">
              <input type="submit" id="submit" className="btn btn-dark" />
            </div>
          </div>
        )}
      </form>
    </>
  );
};
