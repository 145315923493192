import { useEffect, useRef, useState } from "react";
import { FaPrint } from "react-icons/fa";
import DatePicker from "react-datepicker";
import withReactContent from "sweetalert2-react-content";
import { useReactToPrint } from "react-to-print";
import {
  ENDPOINTS,
  PaginatedResult,
  PagingOptions,
  httpService,
} from "../../api";
import FilterByFamily from "../../components/dropdowns/filterByFamily";
import Swal from "sweetalert2";
import FilterByClass from "../../components/dropdowns/filterByClass";
import { ClassAttendanceReportTable } from "./classAttendanceReportTable";
import { ClassMonthlyAttendance } from "@models";

const ClassAttendanceReport = () => {
  const componentRef = useRef(null);
  const [classMonthlyAttendances, setClassMonthlyAttendances] = useState<
    ClassMonthlyAttendance[]
  >([]);

  const [familyId, setFamilyId] = useState<number>();
  const [classId, setClassId] = useState<number>();
  const [className, setClassName] = useState<string | null>(null);

  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [reload, setReload] = useState(false);

  const toggle = withReactContent(Swal);

  const onFamilyChange = (value: any) => {
    setFamilyId(parseInt(value));
  };

  const onClassChange = (value: { id: string; name: string }) => {
    console.log("value", value);
    setClassId(parseInt(value.id));
    setClassName(value.name);
  };

  const onGenerate = async () => {
    setIsGenerating(true);

    if (!selectedDate || !classId) return;

    const res = await httpService(ENDPOINTS.GetClassAttendanceSummary).post({
      month: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
      familyId: familyId ?? null,
      classId: classId ?? null,
    });

    setClassMonthlyAttendances(res.data ?? []);

    setIsGenerating(false);
    setReload(!reload);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {}, [reload]);

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex align-items-start">
        <span className="mt-1 mr-2">
          <DatePicker
            className="form-control form-control-sm"
            onChange={(e) => {
              setSelectedDate(e);
            }}
            selected={selectedDate}
            dateFormat="MM"
            showMonthYearPicker
          />
        </span>
        <span className="mt-1">
          <FilterByClass onHandleChange={onClassChange} />
        </span>
        <span className="mt-1 ml-2">
          <FilterByFamily onHandleChange={onFamilyChange} />
        </span>
        <span className="mx-2 mt-1">
          <button className="btn btn-success" onClick={onGenerate}>
            {isGenerating && (
              <span className="spinner-border spinner-grow-sm text-white mr-2" />
            )}
            Generate
          </button>
        </span>

        <span className="ml-2 mt-1">
          <button
            className="btn btn-primary"
            onClick={handlePrint}
            disabled={setClassMonthlyAttendances.length ? false : true}
          >
            <FaPrint /> Print
          </button>
        </span>
      </div>

      <ClassAttendanceReportTable
        classMonthlyAttendance={classMonthlyAttendances}
        selectedDate={selectedDate}
        className={className}
        ref={componentRef}
      />
    </div>
  );
};

export default ClassAttendanceReport;
