import { Navigate, Route, Routes } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import NotFound from "./notfound";
import DashBoard from "./pages";
import Assessments from "./pages/assessments";
import Classes from "./pages/classes";
import ExamsTable from "./pages/exams/examsTable";
import StudentExamsTable from "./pages/exams/studentExams";
import Login from "./pages/login";
import Families from "./pages/families";
import Subjects from "./pages/subjects";
import Teachers from "./pages/teachers";
import RequiredAuth from "./auth/requiredAuth";
import Users from "./pages/users";
import Profile from "./pages/profile";
import Home from "./pages/home";
import Attendance from "./pages/attendance";
import Reports from "./pages/reports";
import StudentsReport from "./pages/reports/studentReport";
import FamilyReport from "./pages/reports/familyReport";
import ClassReport from "./pages/reports/classReport";
import Assets from "./pages/asset";
import AssetCategories from "./pages/asset/assetCategories";
import MonthlyAttendanceTable from "./pages/reports/monthlyAttendance";
// import MonthlyAttendanceReport from "./pages/reports/monthlyAttendanceReport";
import ClassAttendanceReport from "./pages/reports/classAttendanceReport";
import ClassPeriods from "./pages/reports/classPeriods";
import StudentMilestones from "./pages/reports/studentMilestones";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="dashboard"
          element={
            <RequiredAuth>
              <DashBoard />
            </RequiredAuth>
          }
        >
          <Route path="/dashboard" element={<Home />} />
          <Route path="classes" element={<Classes />} />
          <Route path="families" element={<Families />} />
          <Route path="teachers" element={<Teachers />} />
          <Route path="subjects" element={<Subjects />} />
          <Route path="users" element={<Users />} />
          <Route path="profile" element={<Profile />} />

          <Route path="reports" element={<Reports />}>
            <Route index path="reports" element={<></>} />
            <Route path="students" element={<StudentsReport />} />
            <Route path="classes" element={<ClassReport />} />
            <Route path="families" element={<FamilyReport />} />
            <Route path="attendance" element={<MonthlyAttendanceTable />} />
            <Route path="classPeriods" element={<ClassPeriods />} />
            <Route path="studentMilestone" element={<StudentMilestones />} />

            <Route
              path="attendanceReport"
              element={<ClassAttendanceReport />}
            />
          </Route>

          <Route path="assessments" element={<Assessments />} />
          <Route path="exams" element={<ExamsTable />} />
          <Route path="studentExams" element={<StudentExamsTable />} />
          <Route path="attendance" element={<Attendance />} />
          <Route path="assets" element={<Assets />} />
          <Route path="assets/categories" element={<AssetCategories />} />

          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
