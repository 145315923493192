import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TableHeader, User } from "@models";
import UsersTable from "./usersTable";
import { ENDPOINTS, getUserInfo, httpService } from "../../api";
import { AddUser } from "../../components/forms/addUser";

const headers: TableHeader[] = [
  { id: 1, name: "Full Name", align: "left" },
  { id: 2, name: "User Name", align: "left" },
  { id: 3, name: "Role", align: "left" },
  { id: 4, name: "Created At", align: "left" },
  { id: 5, name: "Actions", align: "left" },
];

const Index = () => {
  const [users, setUsers] = useState<User[]>([]);
  const user = getUserInfo();
  const toggle = withReactContent(Swal);
  const [reload, setReload] = useState(false);

  // Fetch Users
  useEffect(() => {
    (async function fetchUsers() {
      const res = await httpService(ENDPOINTS.Users).getAll();

      setUsers(res.data ?? []);
    })();
  }, [reload]);

  const onCreateUser = () => {
    toggle.fire({
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
      width: "50%",
      html: <AddUser reload={reload} setReload={setReload} />,
    });
  };

  return (
    <div className="widget-content widget-content-area br-6 mt-4 w-70">
      <div className="d-flex align-items-space-between align-content-center">
        <div style={{ flex: 1 }}>{/* left side */}</div>
        {user?.roleName === "Admin" && (
          <>
            <div>
              <button
                className="btn btn-success mr-2"
                onClick={() => onCreateUser()}
              >
                New User
              </button>
            </div>
          </>
        )}
      </div>
      <div className="table-responsive mb-4 px-2 mt-4">
        <UsersTable
          headers={headers}
          users={users}
          reload={reload}
          setReload={setReload}
        />
      </div>
    </div>
  );
};

export default Index;
