import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Assessment, Class, Student, Subject, Teacher } from "@models";
import { useForm } from "react-hook-form";
import { ENDPOINTS, httpService, PagingOptions } from "../../api";
import { useEffect, useState } from "react";

type Props = {
  assessment: Assessment;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};
const EditAssessment = ({ assessment, reload, setReload }: Props) => {
  const [students, setStudents] = useState<Student[]>([]);
  const [subjects, setSubjects] = useState<Subject[]>([]);

  const validationSchema = Yup.object()
    .shape({
      studentId: Yup.number().required("Student id is required"),
      subjectId: Yup.number().required("Subject id is required"),
      remarks: Yup.string().required("Remarks is required"),
    })
    .required();

  const formOptions = {
    defaultValues: {
      studentId: assessment.studentId,
      subjectId: assessment.subjectId,
      remarks: assessment.remarks,
    },
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<Assessment>(formOptions);

  const handleOnSubmit = async (values: Assessment) => {
    await httpService(ENDPOINTS.Assessments).update(assessment.id, values);
    setReload(!reload);
  };

  useEffect(() => {
    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Students, options).getAll();

      setStudents(res.data.items ?? []);
    })();

    (async () => {
      const options = new PagingOptions();
      options.size = 1000;
      const res = await httpService(ENDPOINTS.Subjects, options).getAll();

      setSubjects(res.data.items ?? []);
    })();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select
            {...register("studentId")}
            id="studentId"
            className="form-control"
          >
            <option>Select student</option>
            {students.map((s) => (
              <option
                key={s.id}
                value={s.id}
                selected={s.id === assessment.studentId}
              >
                {`${s.firstName} ${s.lastName}`}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 py-3">
          <select
            {...register("subjectId")}
            id="subjectId"
            className="form-control"
          >
            <option>Select subject</option>
            {subjects.map((s) => (
              <option
                key={s.id}
                value={s.id}
                selected={s.id === assessment.subjectId}
              >
                {s.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 py-3">
          <textarea
            className="form-control mr-2"
            id="remarks"
            placeholder="remarks"
            {...register(`remarks`)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default EditAssessment;
