export enum ENDPOINTS {
  Classes = "classes",
  Students = "students",
  Teachers = "teachers",
  Assessments = "assessments",
  Exams = "exams",
  StudentExams = "studentExams",
  Subjects = "subjects",
  Users = "users",
  Roles = "roles",
  Auth = "auth/login",
  CreateMultipleStudentExams = "studentExams/createManyStudentExams",
  StudentList = "students/list",
  Families = "families",
  AddStudents = "families/addStudents",
  ResetPassword = "users/resetPassword",
  ClassStats = "classes/classStats",
  FamilyStats = "families/familyStats",
  Attendances = "attendances",
  ClassFamilies = "families/class",
  Assets = "Assets",
  AssetCategories = "Assets/Categories",
  AssetStatus = "Assets/status",
  MonthlyAttendance = "monthlyAttendances",
  CreatePeriods = "monthlyAttendances/createPeriods",
  GetClassAttendanceSummary = "monthlyAttendances/getClassAttendanceSummary",
  FamilyQuickSearch = "families/quickSearch",
  ClassPeriods = "classPeriods",
  GetClassFamilies = "families/getClassFamilies",
  GetFamilyStudents = "families/getFamilyStudents",
  StudentsMilestone = "studentsMilestone",
  GetClassFamilyStudents = "families/getClassFamilyStudents",
}
