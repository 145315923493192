import {
  Control,
  FieldValues,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import { Attendance, RoleType } from "@models";
import moment from "moment";
import { getCurrentUser, getUserInfo } from "../../api";
import { ArrowContainer, Popover } from "react-tiny-popover";
import { useState } from "react";

type Props = {
  nestIndex: number;
  control: Control<FieldValues> | undefined;
  register: UseFormRegister<FieldValues>;
  attendances: Attendance[];
};

const NestedArray = ({ nestIndex, control, register, attendances }: Props) => {
  const [currentIdx, setCurrentIdx] = useState<number | null>(null);

  const { fields } = useFieldArray({
    control,
    name: `attendances[${nestIndex}].days`,
  });

  const currentUser = getUserInfo();

  // const noStudents = attendances[nestIndex]?.family?.students?.length;

  function isDisabled(day: number, month: number) {
    if (currentUser?.roleName === RoleType.ADMIN) return false;

    const currentDay = moment().date();
    const currentMonth = moment().month() + 1;

    if (month !== currentMonth) {
      return true;
    }

    if (currentDay >= 11 && day < 11) {
      return true;
    } else if (currentDay >= 21 && day < 21) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      {fields.map((item, idx) => {
        return (
          <td className="p-1 m-1" key={item.id}>
            <Popover
              isOpen={
                currentIdx === idx &&
                attendances[nestIndex]?.remarks.find(
                  (r) => r.day === currentIdx + 1
                )?.comment
                  ? true
                  : false
              }
              positions={["top", "bottom", "left", "right"]} // preferred positions by priority
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor={"blue"}
                  arrowSize={8}
                  arrowStyle={{}}
                  className="popover-arrow-container"
                  arrowClassName="popover-arrow"
                >
                  <div
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      padding: 10,
                      borderRadius: "5%",
                      border: "1px solid blue",
                      width: 200,
                    }}
                    className="comment-popover"
                    onClick={() => setCurrentIdx(null)}
                  >
                    {currentIdx !== null
                      ? attendances[nestIndex]?.remarks.find(
                          (r) => r.day === currentIdx + 1
                        )?.comment ?? ""
                      : ""}
                  </div>
                </ArrowContainer>
              )}
            >
              <div className="att-input">
                <input
                  type="number"
                  {...register(`attendances[${nestIndex}].days.${idx}.minutes`)}
                  className="form-control px-1"
                  name={`attendances[${nestIndex}].days.${idx}.minutes`}
                  disabled={isDisabled(
                    attendances[nestIndex]?.days[idx]?.day ?? 0,
                    attendances[nestIndex]?.month ?? 0
                  )}
                  onFocus={() => {
                    setCurrentIdx(idx);
                  }}
                />
                {attendances[nestIndex]?.remarks.find((r) => r.day === idx + 1)
                  ?.comment && <span className="badge">c</span>}
              </div>
            </Popover>
          </td>
        );
      })}
    </>
  );
};

export default NestedArray;
