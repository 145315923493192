import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Subject } from "@models";
import { useForm } from "react-hook-form";
import { ENDPOINTS, httpService } from "../../api";

type Props = {
  subject: Subject;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};
const EditSubject = ({ subject, reload, setReload }: Props) => {
  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("Name is required"),
    })
    .required();

  const formOptions = {
    defaultValues: { name: subject.name },
    resolver: yupResolver(validationSchema),
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<Subject>(formOptions);

  const handleOnSubmit = async (values: Subject) => {
    await httpService(ENDPOINTS.Subjects).update(subject.id, values);
    setReload(!reload);
  };

  return (
    <form
      onSubmit={handleSubmit(handleOnSubmit)}
      className="d-flex flex-column w-100 mt-4"
    >
      <div className="row w-100">
        <div className="col-sm-12 col-md-12 col-lg-12 py-3">
          <input
            type="text"
            className="form-control mr-2"
            id="name"
            placeholder="Name"
            {...register(`name`)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-success mt-4 d-flex align-items-center justify-content-center"
        >
          {isSubmitting && (
            <span
              style={{ fontSize: "10px", textAlign: "center" }}
              className="spinner-border text-white mr-4"
            />
          )}
          Save
        </button>
      </div>
    </form>
  );
};

export default EditSubject;
